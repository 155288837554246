import { StaticDataQuery, useStaticDataQuery } from '@/graphql';

export type SelectOptionKey = keyof StaticDataQuery['static']['select_options'];

type SelectOptions = Record<string, string>;
type NestedSelectOptions = {
  label: string;
  items: Record<string, string>;
}[];

export const mapSelectOptions = (data: Record<string, string>) => Object.entries(data)
  .map(([value, label]) => ({
    value, label,
  }));

const isGroup = (val: NestedSelectOptions | SelectOptions): val is NestedSelectOptions => Array.isArray(val);

export const useStaticData = () => {
  const { result, refetch } = useStaticDataQuery({
    fetchPolicy: 'cache-first',
  });

  const data = computed(() => result.value?.static);

  const getRaw = <T>(k: SelectOptionKey) => computed(() => {
    const raw = data.value?.select_options;

    if (!raw || !(k in raw)) {
      return [];
    }

    return raw[k] as T;
  });

  const asOptions = (k: SelectOptionKey) => computed(() => mapSelectOptions(getRaw(k).value as Record<string, string>));

  const asGroupOptions = (k: SelectOptionKey) => computed(() => {
    const result = [];

    for (const group of getRaw<NestedSelectOptions>(k).value) {
      const items = mapSelectOptions(group.items);

      result.push({
        label: group.label,
        items: items.length > 0 ? items : [{
          label: group.label, value: group.label,
        }],
      });
    }

    return result;
  });

  const byKey = (collection: SelectOptionKey, key: string | null | undefined) => computed(() => {
    const options = data.value?.select_options;

    if (!options || !(collection in options)) return;

    const colOptions = options[collection] as NestedSelectOptions | SelectOptions;

    if (isGroup(colOptions)) {
      const groups = asGroupOptions(collection).value;
      const map = groups.flatMap(g => g.items);

      return map.find(o => o.value === key)?.label;
    }

    return asOptions(collection).value.find(o => o.value === key)?.label;
  });

  return {
    data,
    byKey,
    refetch,
    asOptions,
    asGroupOptions,
  };
};
