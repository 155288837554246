export const useStates = () => {
  const { data } = useStaticData();

  const options = computed(() => data.value?.states.map(s => ({
    value: s.code,
    label: `${s.code} - ${s.title}`,
  })));

  const find = (code: string) => data.value?.states.find(s => s.code === code);

  return {
    find,
    options,
  } as const;
};
