import { boolean, number, object, string } from 'zod';

export const RoleDTO = object({
  id: number().optional(),
  copy: string().nullish(),
  name: string().min(3),
  description: string().nullish(),
  permissions: object({}).default({}),
  provider_assign: boolean().nullish(),
});
