export const timezones = [{
  label: 'America/Adak UTC-10:00', value: 'America/Adak',
}, {
  label: 'America/Anchorage UTC-09:00', value: 'America/Anchorage',
}, {
  label: 'America/Anguilla UTC-04:00', value: 'America/Anguilla',
}, {
  label: 'America/Antigua UTC-04:00', value: 'America/Antigua',
}, {
  label: 'America/Araguaina UTC-03:00', value: 'America/Araguaina',
}, {
  label: 'America/Argentina UTC-03:00', value: 'America/Argentina',
}, {
  label: 'America/Aruba UTC-04:00', value: 'America/Aruba',
}, {
  label: 'America/Asuncion UTC-04:00', value: 'America/Asuncion',
}, {
  label: 'America/Atikokan UTC-05:00', value: 'America/Atikokan',
}, {
  label: 'America/Bahia UTC-03:00', value: 'America/Bahia',
}, {
  label: 'America/Bahia_Banderas UTC-06:00', value: 'America/Bahia_Banderas',
}, {
  label: 'America/Barbados UTC-04:00', value: 'America/Barbados',
}, {
  label: 'America/Belem UTC-03:00', value: 'America/Belem',
}, {
  label: 'America/Belize UTC-06:00', value: 'America/Belize',
}, {
  label: 'America/Blanc-Sablon UTC-04:00', value: 'America/Blanc-Sablon',
}, {
  label: 'America/Boa_Vista UTC-04:00', value: 'America/Boa_Vista',
}, {
  label: 'America/Bogota UTC-05:00', value: 'America/Bogota',
}, {
  label: 'America/Boise UTC-07:00', value: 'America/Boise',
}, {
  label: 'America/Cambridge_Bay UTC-07:00', value: 'America/Cambridge_Bay',
}, {
  label: 'America/Campo_Grande UTC-04:00', value: 'America/Campo_Grande',
}, {
  label: 'America/Cancun UTC-05:00', value: 'America/Cancun',
}, {
  label: 'America/Caracas UTC-04:00', value: 'America/Caracas',
}, {
  label: 'America/Cayenne UTC-03:00', value: 'America/Cayenne',
}, {
  label: 'America/Cayman UTC-05:00', value: 'America/Cayman',
}, {
  label: 'America/Chicago UTC-06:00', value: 'America/Chicago',
}, {
  label: 'America/Chihuahua UTC-07:00', value: 'America/Chihuahua',
}, {
  label: 'America/Costa_Rica UTC-06:00', value: 'America/Costa_Rica',
}, {
  label: 'America/Creston UTC-07:00', value: 'America/Creston',
}, {
  label: 'America/Cuiaba UTC-04:00', value: 'America/Cuiaba',
}, {
  label: 'America/Curacao UTC-04:00', value: 'America/Curacao',
}, {
  label: 'America/Danmarkshavn UTC+00:00', value: 'America/Danmarkshavn',
}, {
  label: 'America/Dawson UTC-08:00', value: 'America/Dawson',
}, {
  label: 'America/Dawson_Creek UTC-07:00', value: 'America/Dawson_Creek',
}, {
  label: 'America/Denver UTC-07:00', value: 'America/Denver',
}, {
  label: 'America/Detroit UTC-05:00', value: 'America/Detroit',
}, {
  label: 'America/Dominica UTC-04:00', value: 'America/Dominica',
}, {
  label: 'America/Edmonton UTC-07:00', value: 'America/Edmonton',
}, {
  label: 'America/Eirunepe UTC-05:00', value: 'America/Eirunepe',
}, {
  label: 'America/El_Salvador UTC-06:00', value: 'America/El_Salvador',
}, {
  label: 'America/Fortaleza UTC-03:00', value: 'America/Fortaleza',
}, {
  label: 'America/Glace_Bay UTC-04:00', value: 'America/Glace_Bay',
}, {
  label: 'America/Godthab UTC-03:00', value: 'America/Godthab',
}, {
  label: 'America/Goose_Bay UTC-04:00', value: 'America/Goose_Bay',
}, {
  label: 'America/Grand_Turk UTC-05:00', value: 'America/Grand_Turk',
}, {
  label: 'America/Grenada UTC-04:00', value: 'America/Grenada',
}, {
  label: 'America/Guadeloupe UTC-04:00', value: 'America/Guadeloupe',
}, {
  label: 'America/Guatemala UTC-06:00', value: 'America/Guatemala',
}, {
  label: 'America/Guayaquil UTC-05:00', value: 'America/Guayaquil',
}, {
  label: 'America/Guyana UTC-04:00', value: 'America/Guyana',
}, {
  label: 'America/Halifax UTC-04:00', value: 'America/Halifax',
}, {
  label: 'America/Havana UTC-05:00', value: 'America/Havana',
}, {
  label: 'America/Hermosillo UTC-07:00', value: 'America/Hermosillo',
}, {
  label: 'America/Indiana UTC-05:00', value: 'America/Indiana',
}, {
  label: 'America/Inuvik UTC-07:00', value: 'America/Inuvik',
}, {
  label: 'America/Iqaluit UTC-05:00', value: 'America/Iqaluit',
}, {
  label: 'America/Jamaica UTC-05:00', value: 'America/Jamaica',
}, {
  label: 'America/Juneau UTC-09:00', value: 'America/Juneau',
}, {
  label: 'America/Kentucky UTC-05:00', value: 'America/Kentucky/Louisville',
}, {
  label: 'America/Kralendijk UTC-04:00', value: 'America/Kralendijk',
}, {
  label: 'America/La_Paz UTC-04:00', value: 'America/La_Paz',
}, {
  label: 'America/Lima UTC-05:00', value: 'America/Lima',
}, {
  label: 'America/Los_Angeles UTC-08:00', value: 'America/Los_Angeles',
}, {
  label: 'America/Lower_Princes UTC-04:00', value: 'America/Lower_Princes',
}, {
  label: 'America/Maceio UTC-03:00', value: 'America/Maceio',
}, {
  label: 'America/Managua UTC-06:00', value: 'America/Managua',
}, {
  label: 'America/Manaus UTC-04:00', value: 'America/Manaus',
}, {
  label: 'America/Marigot UTC-04:00', value: 'America/Marigot',
}, {
  label: 'America/Martinique UTC-04:00', value: 'America/Martinique',
}, {
  label: 'America/Matamoros UTC-06:00', value: 'America/Matamoros',
}, {
  label: 'America/Mazatlan UTC-07:00', value: 'America/Mazatlan',
}, {
  label: 'America/Menominee UTC-06:00', value: 'America/Menominee',
}, {
  label: 'America/Merida UTC-06:00', value: 'America/Merida',
}, {
  label: 'America/Metlakatla UTC-08:00', value: 'America/Metlakatla',
}, {
  label: 'America/Mexico_City UTC-06:00', value: 'America/Mexico_City',
}, {
  label: 'America/Miquelon UTC-03:00', value: 'America/Miquelon',
}, {
  label: 'America/Moncton UTC-04:00', value: 'America/Moncton',
}, {
  label: 'America/Monterrey UTC-06:00', value: 'America/Monterrey',
}, {
  label: 'America/Montevideo UTC-03:00', value: 'America/Montevideo',
}, {
  label: 'America/Montreal UTC-05:00', value: 'America/Montreal',
}, {
  label: 'America/Montserrat UTC-04:00', value: 'America/Montserrat',
}, {
  label: 'America/Nassau UTC-05:00', value: 'America/Nassau',
}, {
  label: 'America/New_York UTC-05:00', value: 'America/New_York',
}, {
  label: 'America/Nipigon UTC-05:00', value: 'America/Nipigon',
}, {
  label: 'America/Nome UTC-08:00', value: 'America/Nome',
}, {
  label: 'America/Noronha UTC-02:00', value: 'America/Noronha',
}, {
  label: 'America/North_Dakota/Center UTC-06:00', value: 'America/North_Dakota/Center',
}, {
  label: 'America/Nuuk UTC-03:00', value: 'America/Nuuk',
}, {
  label: 'America/Ojinaga UTC-06:00', value: 'America/Ojinaga',
}, {
  label: 'America/Panama UTC-05:00', value: 'America/Panama',
}, {
  label: 'America/Pangnirtung UTC-05:00', value: 'America/Pangnirtung',
}, {
  label: 'America/Paramaribo UTC-03:00', value: 'America/Paramaribo',
}, {
  label: 'America/Phoenix UTC-07:00', value: 'America/Phoenix',
}, {
  label: 'America/Port-au-Prince UTC-05:00', value: 'America/Port-au-Prince',
}, {
  label: 'America/Port_of_Spain UTC-04:00', value: 'America/Port_of_Spain',
}, {
  label: 'America/Porto_Velho UTC-04:00', value: 'America/Porto_Velho',
}, {
  label: 'Europe/Andorra UTC+01:00', value: 'Europe/Andorra',
}, {
  label: 'Europe/Vienna UTC+01:00', value: 'Europe/Vienna',
}, {
  label: 'Europe/Minsk UTC+03:00', value: 'Europe/Minsk',
}, {
  label: 'Europe/Brussels UTC+01:00', value: 'Europe/Brussels',
}, {
  label: 'Europe/Sofia UTC+02:00', value: 'Europe/Sofia',
}, {
  label: 'Europe/Prague UTC+01:00', value: 'Europe/Prague',
}, {
  label: 'Europe/Copenhagen UTC+01:00', value: 'Europe/Copenhagen',
}, {
  label: 'Europe/Tallinn UTC+02:00', value: 'Europe/Tallinn',
}, {
  label: 'Europe/Helsinki UTC+02:00', value: 'Europe/Helsinki',
}, {
  label: 'Europe/Paris UTC+01:00', value: 'Europe/Paris',
}, {
  label: 'Europe/Berlin UTC+01:00', value: 'Europe/Berlin',
}, {
  label: 'Europe/Athens UTC+02:00', value: 'Europe/Athens',
}, {
  label: 'Europe/Budapest UTC+01:00', value: 'Europe/Budapest',
}, {
  label: 'Europe/Rome UTC+01:00', value: 'Europe/Rome',
}, {
  label: 'Europe/Riga UTC+02:00', value: 'Europe/Riga',
}, {
  label: 'Europe/Vaduz UTC+01:00', value: 'Europe/Vaduz',
}, {
  label: 'Europe/Vilnius UTC+02:00', value: 'Europe/Vilnius',
}, {
  label: 'Europe/Luxembourg UTC+01:00', value: 'Europe/Luxembourg',
}, {
  label: 'Europe/Malta UTC+01:00', value: 'Europe/Malta',
}, {
  label: 'Europe/Monaco UTC+01:00', value: 'Europe/Monaco',
}, {
  label: 'Europe/Amsterdam UTC+01:00', value: 'Europe/Amsterdam',
}, {
  label: 'Europe/Oslo UTC+01:00', value: 'Europe/Oslo',
}, {
  label: 'Europe/Warsaw UTC+01:00', value: 'Europe/Warsaw',
}, {
  label: 'Europe/Lisbon UTC+00:00', value: 'Europe/Lisbon',
}, {
  label: 'Europe/Bucharest UTC+02:00', value: 'Europe/Bucharest',
}, {
  label: 'Europe/Moscow UTC+03:00', value: 'Europe/Moscow',
}, {
  label: 'Europe/San_Marino UTC+01:00', value: 'Europe/San_Marino',
}, {
  label: 'Europe/Belgrade UTC+01:00', value: 'Europe/Belgrade',
}, {
  label: 'Europe/Bratislava UTC+01:00', value: 'Europe/Bratislava',
}, {
  label: 'Europe/Ljubljana UTC+01:00', value: 'Europe/Ljubljana',
}, {
  label: 'Europe/Madrid UTC+01:00', value: 'Europe/Madrid',
}, {
  label: 'Europe/Stockholm UTC+01:00', value: 'Europe/Stockholm',
}, {
  label: 'Europe/Zurich UTC+01:00', value: 'Europe/Zurich',
}, {
  label: 'Europe/Kiev UTC+02:00', value: 'Europe/Kiev',
}, {
  label: 'Europe/London UTC+00:00', value: 'Europe/London',
}, {
  label: 'Europe/Gibraltar UTC+01:00', value: 'Europe/Gibraltar',
}, {
  label: 'Europe/Uzhgorod UTC+02:00', value: 'Europe/Uzhgorod',
}, {
  label: 'Europe/Zaporozhye UTC+02:00', value: 'Europe/Zaporozhye',
}, {
  label: 'Europe/Tirane UTC+01:00', value: 'Europe/Tirane',
}, {
  label: 'Europe/Mariehamn UTC+02:00', value: 'Europe/Mariehamn',
}, {
  label: 'Europe/Simferopol UTC+03:00', value: 'Europe/Simferopol',
}, {
  label: 'Europe/Skopje UTC+01:00', value: 'Europe/Skopje',
}, {
  label: 'Europe/Vatican UTC+01:00', value: 'Europe/Vatican',
}, {
  label: 'Europe/Chisinau UTC+02:00', value: 'Europe/Chisinau',
}, {
  label: 'Europe/Busingen UTC+01:00', value: 'Europe/Busingen',
}, {
  label: 'Europe/Sarajevo UTC+01:00', value: 'Europe/Sarajevo',
}, {
  label: 'Europe/Zagreb UTC+01:00', value: 'Europe/Zagreb',
}, {
  label: 'Europe/Istanbul UTC+03:00', value: 'Europe/Istanbul',
}, {
  label: 'Europe/Kaliningrad UTC+02:00', value: 'Europe/Kaliningrad',
}, {
  label: 'Europe/Minsk UTC+03:00', value: 'Europe/Minsk',
}, {
  label: 'Europe/Moscow UTC+03:00', value: 'Europe/Moscow',
}, {
  label: 'Europe/Simferopol UTC+03:00', value: 'Europe/Simferopol',
}, {
  label: 'Europe/Volgograd UTC+04:00', value: 'Europe/Volgograd',
}, {
  label: 'Europe/Samara UTC+04:00', value: 'Europe/Samara',
}, {
  label: 'Asia/Yekaterinburg UTC+05:00', value: 'Asia/Yekaterinburg',
// eslint-disable-next-line max-lines
}, {
  label: 'Asia/Omsk UTC+06:00', value: 'Asia/Omsk',
}, {
  label: 'Asia/Novosibirsk UTC+07:00', value: 'Asia/Novosibirsk',
}, {
  label: 'Asia/Krasnoyarsk UTC+08:00', value: 'Asia/Krasnoyarsk',
}, {
  label: 'Asia/Irkutsk UTC+09:00', value: 'Asia/Irkutsk',
}, {
  label: 'Asia/Yakutsk UTC+10:00', value: 'Asia/Yakutsk',
}, {
  label: 'Asia/Vladivostok UTC+11:00', value: 'Asia/Vladivostok',
}, {
  label: 'Asia/Sakhalin UTC+11:00', value: 'Asia/Sakhalin',
}, {
  label: 'Asia/Magadan UTC+12:00', value: 'Asia/Magadan',
}, {
  label: 'Asia/Kamchatka UTC+12:00', value: 'Asia/Kamchatka',
}, {
  label: 'Asia/Anadyr UTC+12:00', value: 'Asia/Anadyr',
}, {
  label: 'Asia/Aden UTC+03:00', value: 'Asia/Aden',
}, {
  label: 'Asia/Baghdad UTC+03:00', value: 'Asia/Baghdad',
}, {
  label: 'Asia/Bahrain UTC+03:00', value: 'Asia/Bahrain',
}, {
  label: 'Asia/Kuwait UTC+03:00', value: 'Asia/Kuwait',
}, {
  label: 'Asia/Qatar UTC+03:00', value: 'Asia/Qatar',
}, {
  label: 'Asia/Riyadh UTC+03:00', value: 'Asia/Riyadh',
}, {
  label: 'Asia/Tehran UTC+03:30', value: 'Asia/Tehran',
}, {
  label: 'Asia/Baku UTC+04:00', value: 'Asia/Baku',
}, {
  label: 'Asia/Dubai UTC+04:00', value: 'Asia/Dubai',
}, {
  label: 'Asia/Muscat UTC+04:00', value: 'Asia/Muscat',
}, {
  label: 'Asia/Tbilisi UTC+04:00', value: 'Asia/Tbilisi',
}, {
  label: 'Asia/Yerevan UTC+04:00', value: 'Asia/Yerevan',
}, {
  label: 'Asia/Kabul UTC+04:30', value: 'Asia/Kabul',
}, {
  label: 'Asia/Aqtau UTC+05:00', value: 'Asia/Aqtau',
}, {
  label: 'Asia/Aqtobe UTC+05:00', value: 'Asia/Aqtobe',
}, {
  label: 'Asia/Ashgabat UTC+05:00', value: 'Asia/Ashgabat',
}, {
  label: 'Asia/Dushanbe UTC+05:00', value: 'Asia/Dushanbe',
}, {
  label: 'Asia/Karachi UTC+05:00', value: 'Asia/Karachi',
}];
