import { getEnv } from '@/util/runtime';

export { default as AppFeedback } from './components/app-feedback.vue';
export { default as AppFeedbackAlert } from './components/app-feedback-alert.vue';
export { default as AppFeedbackConfirmDialog } from './components/app-feedback-confirm-dialog.vue';
export { default as AppFeedbackToast } from './components/app-feedback-toast.vue';
export * from './models';
export * from './use/confirm-dialog';
export * from './use/service-pages';

export const errorHandler = (err: unknown) => {
  if (getEnv('VITE_APP_ENV') !== 'production') {
    // eslint-disable-next-line no-console
    return console.error(err);
  }

  // @ts-ignore: ??
  if (err && 'message' in err && (err.message as string).includes('Failed to fetch dynamically imported module')) {
    return window.location.reload();
  }

  const { error } = useServicePages();

  void error();
};
