import { CoreUser, isProvider } from '@ml/shared/permissions';
import { find } from 'lodash-es';
import { computed, inject, unref } from 'vue';
import { useRoute } from 'vue-router';

import { MENU_SCHEMA, MenuItem } from '@/module-loader';
import { useMenuStore } from '@/stores/menu';

export const useMenu = () => {
  const route = useRoute();
  const auth = useAuthStore();
  const menuStore = useMenuStore();
  const schema = inject<MenuItem[]>(MENU_SCHEMA);

  const filterFn = (parent?: string | null) => (item: MenuItem) => {
    const p = isProvider(auth.user as CoreUser);

    return (((parent === null && !item.parent) || item.parent === parent)
      && (!item.layout || (item.layout === 'provider' && p)
        || (item.layout === 'admin' && !p)) && (!item.when || item.when.value)
      && (item.visible === undefined || unref(item.visible)))
      && (!item.subRequired || (item.subRequired && getSubItems(item.name).length > 0));
  };

  const sortFn = (a: MenuItem, b: MenuItem) => b.priority - a.priority;

  const items = computed(() => [...schema!].sort(sortFn).filter(filterFn(null)));

  const subItems = computed(() => {
    const parent = route.meta?.menu as string;

    // Untagged routes can't have submenus
    if (!parent) {
      return [];
    }

    return [...schema!].sort(sortFn).filter(filterFn(parent));
  });

  const getSubItems = (parent: string | undefined): MenuItem[] => {
    if (!parent) {
      return [];
    }

    return [...schema!].sort(sortFn).filter(filterFn(parent));
  };

  const subMenuExists = computed(() => subItems.value.length > 0);
  const isSubMenuExist = (parent: string) => {
    if (!parent) return false;

    return [...schema!].some(filterFn(parent));
  };

  const isThirdExist = (parent: string) => find([...schema!], {
    name: parent,
    isThird: true,
  });

  const subMenuVisible = computed(() => subMenuExists.value && menuStore.sub_visible);
  const subVisible = computed(() => menuStore.sub_visible);

  const getFirstLevelPath = (item: MenuItem) => {
    const sub = getSubItems(item.name);

    return sub.length > 0 ? sub[0].path : item.path;
  };

  // eslint-disable-next-line consistent-return
  const getFirstMenuItem = () => {
    for (const item of items.value) {
      const value = unref(item.visible);
      if (!value) {
        continue;
      }

      const subItems = getSubItems(item.name);
      if (subItems.length > 0) return subItems[0];

      return item;
    }
  };

  return {
    toggle: () => menuStore.toggle(),
    items,
    subItems,
    getSubItems,
    getFirstLevelPath,
    isSubMenuExist,
    isThirdExist,
    getFirstMenuItem,
    subMenuExists,
    subMenuVisible,
    subVisible,
  };
};
