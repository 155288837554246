import 'virtual:runtime-config';
import 'virtual:svg-icons-register';
import './assets/styles/app.scss';
import 'core-js/features/array/at';
import 'core-js/features/object/has-own';
import 'core-js/features/array/to-sorted';
import '@morev/vue-transitions/styles';

import VueTransitions from '@morev/vue-transitions';
import { provideApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import PrimeBlocks from 'primeblocks/plugin';
import PrimeVue from 'primevue/config';
import { createApp } from 'vue';
import Vue3linkify from 'vue-3-linkify';
import Toast from 'vue-toastification';
import { globalCookiesConfig } from 'vue3-cookies';
import PerfectScrollbar from 'vue3-perfect-scrollbar';

import { createLoader, MENU_SCHEMA } from '@/module-loader';
import { CredsyPreset } from '@/preset';
import { useEvents } from '@/use/use-events';
import { apolloClient, mapWsEvents, persistor, wsClient } from '@/util/graphql';

import App from './app.vue';
import PrimeVueComponents from './primevue';
import { createAppRouter } from './router';
import { useApp } from './use/app';
import { getEnv } from './util/runtime';

globalCookiesConfig({
  secure: true,
  expireTimes: '365d',
});

const app = createApp(App);

app.config.performance = getEnv('VITE_PERF_HOOKS') === 'true';

useEvents(app);

app.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 5,
  newestOnTop: true,
});
app.use(PrimeVue, {
  ripple: true,
  theme: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    preset: CredsyPreset,
    options: {
      darkModeSelector: '.fake-selector',
      cssLayer: {
        name: 'primevue',
      },
    },
  },
});
app.use(PrimeBlocks);
app.use(PerfectScrollbar);
app.use(PrimeVueComponents);
app.use(createPinia());
app.use(VueTransitions);
app.use(Vue3linkify);

// eslint-disable-next-line unicorn/prefer-top-level-await
(async () => {
  await persistor.restore();
  provideApolloClient(apolloClient);
  mapWsEvents(wsClient);

  const config = await createLoader().load();

  config.plugins.map(p => app.use(p));

  app.provide(MENU_SCHEMA, config.menu);

  const promises = [useApp().init()];

  // @TODO: resolve unleash CORS issue
  // if (import.meta.env.VITE_UNLEASH_URL) {
  //   promises.push(useFlagsStore().load())
  // }

  await Promise.all(promises);

  // use router after fetch as hooks called before mount
  const router = createAppRouter(config.routes);

  app.use(router);

  const preloader = document.querySelector('#app-preloader');

  app.mount('#app');
  preloader?.classList.add('fade-out');

  setTimeout(() => {
    preloader?.remove();
    document.body.classList.remove('overflow-hidden');
  }, 200);
})();
