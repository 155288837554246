import { ApolloQueryResult } from '@apollo/client/core';
import { defineStore } from 'pinia';

import { QueueConfigQuery, useQueueConfigLazyQuery } from '@/graphql';

export interface QueueConfigState {
  concurrent_jobs: number;
  enabled_crawlers: string [];
  license_auto_monitor: boolean;
}

const config = {};

export const useQueueConfigStore = defineStore({
  id: 'queue-config',

  state: () => ({
    config: config as QueueConfigState,
  }),
  actions: {
    fetch() {
      const {
        load, result: queryResult, onResult,
      } = useQueueConfigLazyQuery();

      const result = ref();

      watch(queryResult, res => {
        result.value = res?.queueConfig.config as QueueConfigState;
        if (result.value) this.config = result.value as QueueConfigState;
      }, {
        immediate: true,
      });

      void load();

      onResult((result: ApolloQueryResult<QueueConfigQuery>) => {
        if (result.data?.queueConfig) this.config = result.data.queueConfig.config as QueueConfigState;
      });
    },

    get() {
      return this.config as Partial<QueueConfigState>;
    },
    set(config: Partial<QueueConfigState>) {
      Object.assign(this, {
        config,
      });
    },
  },
  getters: {
    enabledCrawlers(): string[] {
      return this.config?.enabled_crawlers ? ((this.config.enabled_crawlers as unknown) as string).split(',') : [];
    },
  },
});
