import { computed, reactive } from 'vue';

interface Viewport {
  width: number
  height: number
}

const viewport = reactive<Viewport>({
  width: 0,
  height: 0,
});

function setViewport() {
  viewport.width = window?.innerWidth || 0;
  viewport.height = window?.innerHeight || 0;
}

export function useViewport() {
  setViewport();
  window.addEventListener('resize', setViewport);

  return {
    viewport: computed(() => viewport),
  };
}
