import { PersistenceMapperFunction } from 'apollo3-cache-persist/lib/types';

export const persistenceMapper: PersistenceMapperFunction = async (data: string) => {
  const parsed = JSON.parse(data) as Record<string, object>;

  const mapped: Record<string, unknown> = {};
  const persistEntities: [] = [];
  const rootQuery = parsed.ROOT_QUERY as Record<string, unknown>;

  mapped.ROOT_QUERY = Object.keys(rootQuery).reduce(
    (obj: Record<string, unknown>, key: string) => {
      if (['static'].includes(key)) {
        obj[key] = rootQuery[key];
      }

      // if (key.endsWith('@persist')) {
      //   obj[key] = rootQuery[key];

      //   if (Array.isArray(rootQuery[key])) {
      //     const entities = rootQuery[key].map((item: any) => item.__ref);
      //     persistEntities.push(...entities);
      //   } else {
      //     const entity = rootQuery[key].__ref;
      //     persistEntities.push(entity);
      //   }
      // }

      return obj;
    },
    {
      __typename: 'Query',
    },
  );

  persistEntities.reduce((obj, key) => {
    obj[key] = parsed[key];
    return obj;
  }, mapped);

  return JSON.stringify(mapped);
};
