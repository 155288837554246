export interface ICmeCertificate {
  file: string;
  name: string;
}

export enum CmeSettingsReuqired {
  Yes = 'Yes',
  No = 'No',
}

export enum CmeSettingsGracePeriod {
  Yes = 'Yes',
  No = 'No',
}

export enum CmeSettingsRenewal {
  BirthDate = 'BirthDate',
  BirthMonth = 'BirthMonth',
  BirthMonthLastDay = 'BirthMonthLastDay',
  Custom = 'Custom',
  Date = 'Date',
  DateEvenYear = 'DateEvenYear',
  DateOddYear = 'DateOddYear',
  IssueDate = 'IssueDate',
  IssueMonthFirstDay = 'IssueMonthFirstDay',
  Varies = 'Varies',
}

export enum CmeSettingsRequirements {
  Common = 'Common',
  Custom = 'Custom',
}

export enum CmeSettingsType {
  Common = 'Common',
  Custom = 'Custom',
}

export enum CmeSettingsAdditionalTopic {
  Common = 'Common',
  DEA = 'DEA',
  Custom = 'Custom',
  Course = 'Course',
  Topic = 'Topic',
}

export const cmeSettingsTypeOptions = {
  [CmeSettingsType.Custom]: [
    {
      stateCode: 'AZ',
      providerTypeCode: 'DO',
      items: [
        {
          title: 'AOA Category 1A - 24 hours',
          key: {
            value: 'AOA Category 1A - 24 hours',
            type: 'aoa-category-1a',
            hours: 24,
          },
        },
        {
          title: 'AMA PRA Category 1 (no more than 16 hours)',
          key: {
            value: 'AMA PRA Category 1 (no more than 16 hours)',
            type: 'ama-pra-category-1',
            hours: 16,
          },
        },
      ],
    },
  ],
};

export const cmeSettingsAdditionalTopicValues: {
  [key in CmeSettingsAdditionalTopic]: { label: string; value: string; }[]
} = {
  [CmeSettingsAdditionalTopic.Common]: [
    // {
    //   label: 'Infectious Diseases',
    //   value: 'Infectious Diseases',
    // },
  ],
  [CmeSettingsAdditionalTopic.DEA]: [
    // {
    //   label: 'Pain Management and Opioid Use and Addiction',
    //   value: 'Pain Management and Opioid Use and Addiction',
    // },
  ],
  [CmeSettingsAdditionalTopic.Custom]: [
    {
      label: 'Controlled Substances - 2 Credits - every 2 years',
      value: 'Controlled Substances - 2 Credits - every 2 years',
    },
  ],
  [CmeSettingsAdditionalTopic.Course]: [],
  [CmeSettingsAdditionalTopic.Topic]: [],
};

export interface CmeCycleParams {
  value: number; period: string; year?: number;
}

export interface SetCmeCycleParams {
  cycles: CmeCycleParams[];
  currentYear: number;
  settingsPeriod: number;
  date: Date;
  currentDate: Date;
}
