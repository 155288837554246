export const columnsByRoute = {
  licenses: [
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Request', field: 'request_type', hidden: false,
    },
    {
      label: 'Progress', field: 'request.status', sortField: 'sort_rel_request__status', hidden: false,
    },
    {
      label: 'Prof', field: 'provider.type_code', sortField: 'sort_rel_provider__type_code', hidden: false,
    },
    {
      label: 'Type', field: 'type', hidden: false,
    },
    {
      label: 'State', field: 'state.code', sortField: 'sort_rel_state__title', hidden: false,
    },
    {
      label: 'Number', field: 'number', hidden: false,
    },
    {
      label: 'Expires', field: 'expires_at', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Verified', field: 'verified', hidden: false,
    },
  ],
  'licenses-auto-renewal': [
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Prof', field: 'provider.type_code', sortField: 'sort_rel_provider__type_code', hidden: false,
    },
    {
      label: 'Type', field: 'type', hidden: false,
    },
    {
      label: 'State', field: 'state.code', sortField: 'sort_rel_state__title', hidden: false,
    },
    {
      label: 'Number', field: 'number', hidden: false,
    },
    {
      label: 'Expires', field: 'expires_at', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Verified', field: 'verified', hidden: false,
    },
  ],
  'licenses-all-requests': [
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Request', field: 'request_type', hidden: false,
    },
    {
      label: 'Progress', field: 'request.status', sortField: 'sort_rel_request__status', hidden: false,
    },
    {
      label: 'Prof', field: 'provider.type_code', sortField: 'sort_rel_provider__type_code', hidden: false,
    },
    {
      label: 'Type', field: 'type', hidden: false,
    },
    {
      label: 'State', field: 'state.code', sortField: 'sort_rel_state__title', hidden: false,
    },
    {
      label: 'Number', field: 'number', hidden: false,
    },
    {
      label: 'Expires', field: 'expires_at', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
  ],
  'licenses-completed': [
    {
      label: 'ID', field: 'request_id', sortField: 'id', hidden: false,
    },
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Prof', field: 'provider.type_code', sortField: 'sort_rel_provider__type_code', hidden: false,
    },
    {
      label: 'Type', field: 'type', sortField: 'sort_rel_license__type', hidden: false,
    },
    {
      label: 'State', field: 'state.code', sortField: 'sort_rel_state__title', hidden: false,
    },
    {
      label: 'Number', field: 'number', sortField: 'sort_rel_license__number', hidden: false,
    },
    {
      label: 'Expires', field: 'expires_at', sortField: 'sort_rel_license__expires_at', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Verified', field: 'verified', sortField: 'sort_rel_license__verified', hidden: false,
    },
  ],
  'licenses-closed': [
    {
      label: 'ID', field: 'request_id', sortField: 'id', hidden: false,
    },
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Prof', field: 'provider.type_code', sortField: 'sort_rel_provider__type_code', hidden: false,
    },
    {
      label: 'Type', field: 'type', sortField: 'sort_rel_license__type', hidden: false,
    },
    {
      label: 'State', field: 'state.code', sortField: 'sort_rel_state__title', hidden: false,
    },
    {
      label: 'Number', field: 'number', sortField: 'sort_rel_license__number', hidden: false,
    },
    {
      label: 'Expires', field: 'expires_at', sortField: 'sort_rel_license__expires_at', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Notes', field: 'notes', hidden: false, notSort: true,
    },
  ],
  providers: [
    {
      label: 'Name', field: 'fullName', sortField: 'first_name', hidden: false,
    },
    {
      label: 'Profession', field: 'type_code', hidden: false,
    },
    {
      label: 'Specialty', field: 'specialty', hidden: false,
    },
    {
      label: 'NPI', field: 'npi.number', sortField: 'sort_rel_npi__number', hidden: false,
    },
    {
      label: 'Profile', field: 'profile_completion', notSort: true, hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Assign', field: 'assignees.id', notSort: true, hidden: false,
    },
  ],
  'my-providers': [
    {
      label: 'Name', field: 'fullName', sortField: 'first_name', hidden: false,
    },
    {
      label: 'Profession', field: 'type_code', hidden: false,
    },
    {
      label: 'Specialty', field: 'specialty', hidden: false,
    },
    {
      label: 'NPI', field: 'npi.number', sortField: 'sort_rel_npi__number', hidden: false,
    },
    {
      label: 'Profile', field: 'profile_completion', notSort: true, hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
  ],
  documents: [
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Type', field: 'type', hidden: false,
    },
    {
      label: 'File name', field: 'name', hidden: false,
    },
    {
      label: 'Issued at', field: 'extra.issued_at', sortField: 'sort_extra__issued_at', hidden: false,
    },
    {
      label: 'Expiration', field: 'extra.expires_at', sortField: 'sort_extra__expires_at', hidden: false,
    },
    {
      label: 'Access', field: 'visibility', hidden: false,
    },
  ],
  logins: [
    {
      label: 'Provider', field: 'full_name', sortField: 'sort_rel_provider__first_name', hidden: false,
    },
    {
      label: 'Title', field: 'title', hidden: false,
    },
    {
      label: 'Login', field: 'login', hidden: false,
    },
    {
      label: 'Password', field: 'password', hidden: false, notSort: true,
    },
    {
      label: 'Website URL', field: 'website', hidden: false,
    },
    {
      label: 'Notes', field: 'note', hidden: false, notSort: true,
    },
  ],
  organizations: [
    {
      label: 'Name', field: 'name', hidden: false,
    },
    {
      label: '# Providers', field: 'providers_count', hidden: false, notSort: true,
    },
    {
      label: 'NPI', field: 'npi', hidden: false,
    },
    {
      label: 'Phone', field: 'phone', hidden: false,
    },
    {
      label: '# Licenses', field: 'licenses_count', hidden: false, notSort: true,
    },
    {
      label: 'Email', field: 'email', hidden: false,
    },
  ],
  'settings-team': [
    {
      label: 'Name', field: 'fullNameUser', sortField: 'first_name', hidden: false,
    },
    {
      label: 'Email', field: 'email', hidden: false,
    },
    {
      label: 'Phone', field: 'phone', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Organization', field: 'org_id', hidden: false,
    },
    {
      label: 'User role', field: 'roles.id', hidden: false, notSort: true,
    },
  ],
  'settings-providers': [
    {
      label: 'Name', field: 'fullName', sortField: 'first_name', hidden: false,
    },
    {
      label: 'Email', field: 'email', hidden: false,
    },
    {
      label: 'Phone', field: 'phone', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
  ],
  'provider-groups': [
    {
      label: 'Name', field: 'name', hidden: false,
    },
    {
      label: 'Providers', field: 'providers_count', hidden: false, notSort: true,
    },
    {
      label: 'Licenses', field: 'licenses_count', hidden: false, notSort: true,
    },
    {
      label: 'Owner', field: 'owner', sortField: 'sort_rel_user__first_name', hidden: false,
    },
    {
      label: 'Description', field: 'description', hidden: false,
    },
  ],
  'group-profile': [
    {
      label: 'Name', field: 'fullName', hidden: false,
    },
    {
      label: 'Profession', field: 'type_code', hidden: false,
    },
    {
      label: 'Specialty', field: 'specialty', hidden: false,
    },
    {
      label: 'NPI', field: 'npi.number', hidden: false,
    },
    {
      label: 'Profile', field: 'profile_completion', hidden: false,
    },
    {
      label: 'Status', field: 'status', hidden: false,
    },
    {
      label: 'Assign', field: 'assignees.id', hidden: false,
    },
  ],
};
