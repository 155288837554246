<template>
  <div class="app-filter-activator min-width-0">
    <slot />
    <span
      class="flex-ellipsis"
      :class="{'app-filter-activator__text__active': isActive}"
    >{{ header }}</span>

    <button
      class="app-filter-activator__icon-wrapper"
      :class="{'app-filter-activator__icon-wrapper__active': isActive}"
      @click.stop="handleClick"
    >
      <app-icon
        :name="iconName"
        size="20"
        :width="iconName === 'search' ? 'bold' : 'normal'"
        class="app-filter-activator__icon"
        :color="isActive ? 'primary-600' : 'gray-300'"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';

import { useGlobal } from '@/use/use-global';

import { events as eventsList } from '../constants';
import { FilterActivatorProps, FilterType } from '../models/types';
import { useFilter } from '../use/filter-impl';

const { onEvents, offEvents } = useGlobal();

const props = withDefaults(defineProps<FilterActivatorProps>(), {
  type: FilterType.Selection, options: () => [],
});

const emit = defineEmits(eventsList.map(event => event.name));

const { handleActivatorClick, isFilterActive } = useFilter(props);

const handleClick = (e: MouseEvent) => handleActivatorClick(props, e);

const isActive = computed(() => isFilterActive(props.field));
const iconName = computed(() => {
  if (props.type && [FilterType.DateUpper, FilterType.DateLower, FilterType.Selection].includes(props.type)) {
    if (isActive.value) return 'Filters lines';
    if (!isActive.value) return 'filter';
  }

  if (props.type === FilterType.Settings) return 'settings';

  return 'search';
});

onMounted(() => onEvents(eventsList.map(event => ({
  name: event.name, callback: data => emit(event.name, data),
}))));
onBeforeUnmount(() => offEvents(eventsList.map(event => event.name)));
</script>

<style lang="scss">
.app-filter-activator {
  display: flex;
  align-items: center;

  &__text {

    &__active {
      color: var(--primary-600);
    }
  }

  &-settings {
    position: relative;
    right: 10px;
  }

  &__icon-wrapper {
    margin-left: 0.3rem;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 50%;
    transition: background-color 0.2s;

    &__active {
      background: #FFFFFF;
    }

    &:hover {
      background: #FFFFFF;

      .app-filter-activator__icon {
        stroke: var(--gray-600) !important;
      }
    }
  }
}
</style>
