<template>
  <component
    :is="svgComponent"
    :width="width"
    :height="height"
  />
</template>

<script setup>
import { shallowRef } from 'vue';

const props = defineProps({
  src: {
    type: Object,
    required: true,
  },
  width: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
});

const svgComponent = shallowRef(null);

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
props.src
// eslint-disable-next-line unicorn/prefer-top-level-await
  .then(module => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    svgComponent.value = module.default;
  });
</script>
