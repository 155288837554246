import { useRoute, useRouter } from 'vue-router';

export const useServicePages = () => {
  const r = useRoute();
  const router = useRouter();

  const push = (name: string) => router?.push({
    name,
    hash: r.hash,
    query: r.query,
    params: {
      pathMatch: r.path.slice(1).split('/'),
    },
  });

  const error = () => push('error');
  const notFound = () => push('not-found');
  const forbidden = () => push('forbidden');
  const forbiddenSharePdf = () => push('forbidden-share-pdf');
  const notFoundSharePdf = () => push('not-found-share-pdf');

  return {
    error,
    notFound,
    forbidden,
    forbiddenSharePdf,
    notFoundSharePdf,
  };
};
