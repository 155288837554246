import PrimeContext from 'primevue/contextmenu';

type ContextMenuStoreType = Record<string, PrimeContext>;

export const useContextMenuStore = defineStore({
  id: 'context-menu',
  state: () => ({
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data: {} as ContextMenuStoreType,
  }),

  actions: {
    set(id: string, value?: PrimeContext) {
      if (!value) return;

      this.data[id] = value;
    },
    get(): Record<string, PrimeContext> {
      return this.data;
    },
  },
});
