import { computed, ref } from 'vue';

import { FilterDateModel, FilterMode, FilterModel, FilterValue } from '@/modules/filters/models/types';

import { FilterActivatorProps, FilterType } from '../models/types';

const filterProps = ref<FilterActivatorProps | undefined>(undefined);
const activeFields = ref<string[]>([]);
const position = ref<{ top: string, left: string }>({
  top: `${0}px`,
  left: `${0}px`,
});

const model = ref<Record<string, FilterModel | undefined>>({});
const cachedModel = ref<Record<string, FilterValue>>({});
const someFilterSelected = computed(() => activeFields.value.length > 0);

const isShow = ref(false);
const close = () => {
  if (filterProps.value?.events?.onSearch) filterProps.value?.events?.onSearch('');

  isShow.value = false;
};
const show = () => {
  isShow.value = true;
};

function getParsedCache(mode: FilterMode, cachedValue: FilterValue) {
  if (![FilterMode.DATE_IS_JSON, FilterMode.DATE_IS].includes(mode)) return cachedValue;
  if (typeof cachedValue !== 'object'
      || Array.isArray(cachedValue)
      || (!cachedValue?.lower && !cachedValue?.upper)
  ) return cachedValue;

  const newObj: FilterDateModel = {
    upper: cachedValue.upper ? new Date(cachedValue.upper) : undefined,
    lower: cachedValue.lower ? new Date(cachedValue.lower) : undefined,
  };
  return newObj;
}

export function useFilter(props?: FilterActivatorProps) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleActivatorClick = (props: FilterActivatorProps, evt: MouseEvent) => {
    if (isShow.value && props.field === filterProps.value?.field) {
      close();
      return;
    }

    filterProps.value = {
      ...props,
      placeholder: props.header ?? 'Search by',
      type: props.type ?? FilterType.Selection,
      customSearch: props.customSearch,
    };

    const windowWidth = window.innerWidth;
    const fontSize = Number
      .parseFloat(getComputedStyle(document.documentElement)
        .fontSize.replace('px', ''));

    const target = (evt?.currentTarget ?? evt?.currentTarget) as (HTMLElement | null);
    if (!target) return;
    const rect = target.getBoundingClientRect();

    const rightOffset = 2 * fontSize;
    const bottomOffset = 1.125 * fontSize;

    const left = rect.left + fontSize * 20 > windowWidth - rightOffset
      ? windowWidth - rightOffset - fontSize * 20
      : rect.left;

    position.value.top = `${bottomOffset + rect.bottom}px`;
    position.value.left = `${left}px`;

    show();
  };

  if (props && !model.value[props.field]) {
    model.value[props.field] = {
      value: getParsedCache(props.mode, cachedModel.value[props.field]) ?? undefined,
      mode: props.mode,
      replaceSpaces: props.replaceSpaces,
      graphqlFilter: props.graphqlFilter ?? undefined,
    };
  }

  const isFilterActive = (field: string) => (isShow.value && field === filterProps.value?.field)
      || activeFields.value.includes(field);

  return {
    model,
    someFilterSelected,
    filterProps,
    position,
    activeFields,
    cachedModel,
    isFilterActive,
    handleActivatorClick,
    isShow,
    show,
    close,
  };
}
