import { MergedConfig, ModuleConfig } from './types';

export const MENU_SCHEMA = Symbol('App menu schema');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const moduleMapper = (merged: MergedConfig) => async ([_, importFn]) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  const module: ModuleConfig = await importFn();

  if (module.plugin) {
    merged.plugins.push(module.plugin);
  }

  if (module.menu) {
    merged.menu.push(...module.menu);
  }

  if (module.routes) {
    merged.routes.push(...module.routes);
  }
};

export const createLoader = () => {
  const modules = import.meta.glob('/src/modules/**/module.ts');

  const load = async () => {
    const merged: MergedConfig = {
      menu: [],
      routes: [],
      plugins: [],
    };

    await Promise.all(
      Object.entries(modules).map(moduleMapper(merged)),
    );

    return merged;
  };

  return {
    load,
  };
};
