import { EnumMapping } from './types';

export enum LicenseType {
  DEA = 'dea',
  CSR = 'csr',
  State = 'state',
}

export enum LicenseStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Closed = 'closed',
  Expired = 'expired',
  Issue = 'issue',
  None = 'none',
  Other = 'other',
}

export const LicenseStatusMapping: EnumMapping<LicenseStatus> = {
  [LicenseStatus.Active]: 'Active',
  [LicenseStatus.Cancelled]: 'Cancelled',
  [LicenseStatus.Closed]: 'Closed',
  [LicenseStatus.Expired]: 'Expired',
  [LicenseStatus.Issue]: 'Issue',
  [LicenseStatus.None]: 'None',
  [LicenseStatus.Other]: 'Other',
};

export enum LicenseRequestType {
  New = 'new',
  Renew = 'renew',
  Reinstatement = 'reinstatement',
}

export const LicenseRequestTypeMapping: EnumMapping<LicenseRequestType> = {
  [LicenseRequestType.New]: 'New',
  [LicenseRequestType.Renew]: 'Renew',
  [LicenseRequestType.Reinstatement]: 'Reinstatement',
};

export enum LicenseVerificationStatus {
  Active = 'active',
  Inactive = 'inactive',
  Failed = 'failed',
  NotVerified = 'not_verified',
}

export const LicenseVerificationStatusMapping: EnumMapping<LicenseVerificationStatus> = {
  [LicenseVerificationStatus.Active]: 'Active',
  [LicenseVerificationStatus.Inactive]: 'Inactive',
  [LicenseVerificationStatus.Failed]: 'Failed',
  [LicenseVerificationStatus.NotVerified]: 'Not Verified',
};

export enum LicenseRequestServicesType {
  NewLicense = 'New License',
  Renew = 'Renew',
  AutoRenew = 'Autorenew',
  Other = 'Other',
}

export enum LicenseProgress {
  Review = 'review',
  AwaitingPayment = 'awaiting_payment',
  ApplicationProcessing = 'application_processing',
  BoardProcessing = 'board_processing',
}

export enum LicenseRequestStatuses {
  Review = 'Draft Approval',
  Payment = 'State Fee Payment',
  BoardProcessing = 'Board Processing',
  Completed = 'Completed',
}

export const LicenseRequestStatusMapping: EnumMapping<LicenseRequestStatuses> = {
  [LicenseRequestStatuses.Review]: 'Draft Approval',
  [LicenseRequestStatuses.Payment]: 'State Fee Payment',
  [LicenseRequestStatuses.BoardProcessing]: 'Board Processing',
  [LicenseRequestStatuses.Completed]: 'Completed',
};

export enum LicenseRequestIdType {
  App = 'A',
  Credsy = 'C',
}
