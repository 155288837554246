import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerGraphQL from '@openreplay/tracker-graphql';

import { getEnv } from './runtime';

export const OpenReplay = new Tracker({
  projectKey: getEnv('VITE_OR_PROJECT')!,
  ingestPoint: getEnv('VITE_OR_INGEST'),
  __DISABLE_SECURE_MODE: getEnv('VITE_APP_ENV') === 'dev',
});

OpenReplay.use(trackerAssist());

export const recordGraphQL = OpenReplay.use(trackerGraphQL());
