<template>
  <span
    class="app-verified-tag"
    :class="tag.value"
  >
    <app-icon
      :size="iconSize"
      :name="tag.icon"
      :class="tag.iconClass"
    />
  </span>
</template>

<script lang="ts" setup>
import { LicenseVerificationStatus } from '@/graphql';

const props = withDefaults(defineProps<{
  status?: LicenseVerificationStatus | null,
  isWarning?: boolean,
  isFailed?: boolean
  type?: string
  iconSize?: number,
  lastParse?: number | null,
}>(), {
  iconSize: 16,
  status: LicenseVerificationStatus.NotVerified,
  type: '',
  lastParse: null,
});

const info = {
  [LicenseVerificationStatus.Active]: {
    iconClass: 'app-verified-tag--active-icon',
    icon: 'check-circle',
    value: 'app-verified-tag--active',
  },
  [LicenseVerificationStatus.Inactive]: {
    iconClass: 'app-verified-tag--warning-icon',
    icon: 'alert-triangle',
    value: 'app-verified-tag--warning',
  },
  [LicenseVerificationStatus.Failed]: {
    iconClass: 'app-verified-tag--failed-icon',
    icon: 'alert-circle',
    value: 'app-verified-tag--failed',
  },
  [LicenseVerificationStatus.NotVerified]: {
    iconClass: 'app-verified-tag--other-icon',
    icon: 'zap',
    value: 'app-verified-tag--other',
  },
};

const fallback = {
  icon: 'zap',
  value: ' app-verified-tag--other',
  iconClass: 'app-verified-tag--other-icon',
};

const getTag = (status: LicenseVerificationStatus, lastParse: number | null | undefined) => {
  if (status === LicenseVerificationStatus.Inactive && props.type === 'table' && !lastParse) {
    return fallback;
  }

  return info[status];
};

const tag = computed(() => {
  if (props.status) {
    return getTag(props.status, props.lastParse);
  }

  return fallback;
});
</script>

<style lang="scss">
@mixin app-verified-tag($color) {
  background: var(--#{$color}-100);
  border: 4px solid var(--#{$color}-50);
  border-radius: 28px;
  padding: 1.5px 4px; // @TODO: remove padding

  &-icon {
    stroke: var(--#{$color}-600) !important;
    position: relative;
    top: 3px;
  }
}

.app-verified-tag {
  &--other {
    @include app-verified-tag(gray);
  }
  &--active {
    @include app-verified-tag(success);
  }
  &--warning {
    @include app-verified-tag(warning);
  }
  &--failed {
    @include app-verified-tag(error);
  }
}
</style>
