import { countries } from '@ml/shared';

import { CountryCode } from '@/graphql';
import { createEnumUse } from '@/util/enums';

const countryOrder: Partial<Record<CountryCode, number>> = {
  [CountryCode.Us]: 1,
  [CountryCode.Ca]: 2,
};

const useCountriesInternal = createEnumUse(CountryCode, countries);

export const useCountries = () => {
  const { options, find } = useCountriesInternal();

  const sortedOptions = (options as unknown[])
    .toSorted(({ value: k1, label: t1 }, { value: k2, label: t2 }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const o1 = countryOrder[k1] || Number.MAX_SAFE_INTEGER;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      const o2 = countryOrder[k2] || Number.MAX_SAFE_INTEGER;

      if (o1 !== o2) { return o1 - o2; }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      return t1.localeCompare(t2);
    });

  return {
    find,
    options: sortedOptions,
  };
};

// export const sortedCountries = getSortedCountries();
