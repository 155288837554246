export const normalizeColor = (color?: string, fade = 500): string => {
  if (!color) {
    return normalizeColor('gray', fade);
  }

  if (color.startsWith('#') || color.startsWith('var')) {
    return color;
  }
  const name = color ?? 'gray';

  if (name.includes('-')) {
    return `var(--${name})`;
  }

  return `var(--${name}-${fade})`;
};
