import { timezones } from '@ml/shared';
import { find } from 'lodash-es';

import { useMeLazyQuery, useOnLogoutSubscription, useOnMeSubscription, useUpdateOneUserMutation } from '@/graphql';
import { useQueueConfigStore } from '@/modules/queue';
import { useCmeSettingsStore } from '@/modules/settings/stores/cme-settings';
import { OpenReplay } from '@/util/openreplay';
import { getEnv } from '@/util/runtime';

export const useApp = () => {
  const auth = useAuthStore();
  const data = useStaticData();
  const queueConfig = useQueueConfigStore();
  const cmeSettingsStore = useCmeSettingsStore();

  const { load: getCurrentUser, onResult: onUser } = useMeLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { mutate } = useUpdateOneUserMutation();

  queueConfig.fetch();
  cmeSettingsStore.fetch();

  const init = () => new Promise(resolve => {
    onUser(({ data }) => {
      if (data?.me) {
        auth.user = data.me.user;
        auth.session = data.me.session;
        auth.trial = data.me.trial;

        if (data.me.user?.email) {
          OpenReplay.setUserID(data.me.user.email);
        }

        if (data.me.user?.id) {
          const timezone = find(timezones, {
            value: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });

          if (!auth.session?.parent_user_id && !auth.user?.timezone && timezone) {
            void mutate({
              id: auth.user?.id!,
              data: {
                timezone: timezone.value,
              },
            });
          }

          const { onResult: onLogout } = useOnLogoutSubscription({
            user_id: data.me.user?.id,
          });
          const { onResult: onMe } = useOnMeSubscription({
            user_id: data.me.user?.id,
          });

          onLogout(() => window.location.reload());
          onMe(result => {
            if (result?.data?.onMe) {
              auth.user = result?.data?.onMe.user;
              auth.session = result?.data?.onMe.session;
              auth.trial = result?.data?.onMe.trial;
            }
          });
        }
      }

      if (auth.isProvider && getEnv('VITE_OLARK_ID')) {
        void useOlark().init();
      }

      if (data) {
        resolve(true);
      }
    });

    void getCurrentUser();

    void data.refetch();
  });

  return {
    init,
  };
};
