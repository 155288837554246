import 'primeicons/primeicons.css'
import '@/assets/vendor/primeblocks/styles/demo/flag.css'

import { reactive } from 'vue'

import CodeHighlight from './AppCodeHighlight'
import BlockViewer from './components/blockviewer/BlockViewer.vue'

export default {
  install(app) {
    app.config.globalProperties.$appState = reactive({
      theme: 'lara-light-indigo', dark: false 
    })

    app.component('BlockViewer', BlockViewer)
    app.directive('code', CodeHighlight)
  }
}
