// Core role presets

export interface CoreUser {
  roles: {
    slug: Roles;
  }[];
}

export enum Roles {
  Provider = 'provider',
  Superadmin = 'superadmin',
  CompanyAdmin = 'companyadmin',
}

export const isProvider = (user: CoreUser): boolean => {
  if (!user) {
    return false;
  }

  return Boolean(!user.roles || user.roles.some(r => r.slug === Roles.Provider));
};

export const isSuperadmin = (user: CoreUser): boolean => Boolean(user.roles.some(r => r.slug === Roles.Superadmin));
export const isCompanyAdmin = (user: CoreUser): boolean => Boolean(user.roles.some(r => r.slug === Roles.CompanyAdmin));
