import { getEnv } from '@/util/runtime';

const show = () => {
  window.olark('api.box.expand');
};

const loadScript = (): Promise<Event> => new Promise((resolve, reject) => {
  const head = document.head || document.querySelectorAll('head')[0];
  let script: HTMLScriptElement & {
    _?: { s: []; t: number[]; c: unknown; l: string; };
    extend?: (i: unknown, j: unknown) => void;
    identify?: (i: unknown) => void;
    configure?: (i: unknown, j: unknown) => void;
  } = document.createElement('script');

  script.async = true;
  const link = 'static.olark.com/jsclient/loader.js';
  script.src = `//${link}`;

  head.append(script);

  script.addEventListener('load', resolve);
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  script.onerror = reject;

  // @ts-ignore: ??
  // eslint-disable-next-line no-multi-assign,func-names
  script = window.olark = function () {
    // @ts-ignore: ??
    // eslint-disable-next-line prefer-rest-params
    k.s.push(arguments);
    k.t.push(Date.now());
  };
  // @ts-ignore: ??
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  script.extend = (i, j) => script('extend', i, j);
  // @ts-ignore: ??
  // eslint-disable-next-line no-return-assign,@typescript-eslint/no-unsafe-return
  script.identify = i => script('identify', k.i = i);
  script.configure = (i, j) => {
    // @ts-ignore: ??
    script('configure', i, j);
    // @ts-ignore: ??
    k.c[i] = j;
  };

  // eslint-disable-next-line no-multi-assign
  const k = script._ = {
    s: [],
    t: [Date.now()],
    c: {},
    l: link,
  };
});

const init = (): Promise<Event | void> => {
  const id = getEnv('VITE_OLARK_ID');

  return new Promise((resolve, reject) => {
    const funcToCall = () => {
      loadScript().then(() => {
        const { olark } = window;

        olark.identify(id);
        olark.configure('system.hb_chatbox_size', 'md');
        olark.configure('system.hb_position', 'left');
        olark('api.box.hide');
        olark('api.box.onShrink', () => {
          olark('api.box.hide');
        });
        resolve();
      }, reject);
    };

    if (document.readyState === 'complete') funcToCall();
    else window.addEventListener('load', funcToCall, false);
  });
};

export const useOlark = () => ({
  init, show,
});
