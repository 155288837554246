import { JobDirectionType } from './job-direction-type';

export const attemptsOptions = {
  [JobDirectionType.Manual]: {
    attempts: 3,
    priority: 1,
  },
  [JobDirectionType.Auto]: {
    attempts: 10,
    backoff: {
      type: 'exponential',
      delay: 60 * 1000,
    },
  },
};
