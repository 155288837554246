import emitter from 'tiny-emitter/instance';
import { App } from 'vue';

type Fn = (...args: unknown[]) => void;

export const useEvents = (app: App) => {
  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$events = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    $on: (event: string, callback: Fn, ctx?: unknown) => emitter.on(event, callback, ctx),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    $once: (event: string, callback: Fn, ctx?: unknown) => emitter.once(event, callback, ctx),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    $emit: (event: string, ...args: unknown[]) => emitter.emit(event, ...args),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    $off: (event: string, callback?: Fn) => emitter.off(event, callback),
  };
};
