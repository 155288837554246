export enum Page {
  Dashboard = 'page:dashboard',
  AllProviders = 'page:all-providers',
  Groups = 'page:groups',
  Settings = 'page:settings',
  Team = 'page:team-settings',
  Permissions = 'page:permissions',
  Organizations = 'page:organizations',

  TeamUserSettings = 'page:team-user-settings',

  ProviderUsers = 'page:settings-providers',
  ProviderUserSettings = 'page:provider-user-settings',

  AllDocuments = 'page:all-documents',
  AllLicenses = 'page:all-licenses',
  AllCredentials = 'page:all-credentials',
  LicensesAllRequests = 'page:licenses-all-requests',
  LicensesCompletedRequests = 'page:licenses-completed-requests',
  LicensesClosedRequests = 'page:licenses-closed-requests',
  LicensesAutoRenewal = 'page:licenses-auto-renewal',

  CmeCredits = 'page:cme-credits',
  CmeSettings = 'page:cme-settings',
  CmeRequirements = 'page:cme-requirements',
}
