export const FORM_FIELDS_SYMBOL = Symbol('FORM_FIELDS');
export const SCROLL_MENU_SYMBOL = Symbol('SCROLL_MENU');

export enum FEATURE_FLAGS {
  // Pages
  SUPPORT_PAGE = 'support_page',
  LICENSES_PAGE = 'licenses_page',
  PROVIDER_GROUPS = 'provider_groups',

  // Settings
  GLOBAL_SETTINGS = 'global_settings',
  SETTINGS_PERMISSIONS = 'settings_permissions',
  SETTINGS_USERS = 'settings_users',
  SETTINGS_TEAM = 'settings_team',
  SETTINGS_GENERAL = 'settings_general',

  // Documents
  DOCUMENTS_PAGE = 'documents_page',
  PROVIDER_PROFILE_DOCUMENTS = 'provider_profile_documents',

  // User profile
  PROFILE_PERSONAL_INFO = 'profile_personal_info',
  PROFILE_AUTHENTICATION = 'profile_authentication',
}

export const phoneMasks = [
  {
    name: 'US',
    value: '(999) 999-9999',
  },
  {
    name: 'GB',
    value: '09999 999999',
  },
];
