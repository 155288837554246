export enum Provider {
  Tenancy = 'provider',
}

export enum ProviderTenancy {
  B2B = 'b2b',
  B2C = 'b2c',
  Organization = 'org',
  Assigned = 'assigned',
}

export enum ProviderField {
  ProfileStatus = 'status',
}
