import * as Codegen from './codegen';

export const countries = {
  [Codegen.CountryCode.Us]: 'United States',
  [Codegen.CountryCode.At]: 'Austria',
  [Codegen.CountryCode.Km]: 'Comoros',
  [Codegen.CountryCode.Ga]: 'Gabon',
  [Codegen.CountryCode.Ps]: 'Palestinian Territory',
  [Codegen.CountryCode.Gp]: 'Guadeloupe',
  [Codegen.CountryCode.Ir]: 'Iran',
  [Codegen.CountryCode.Hn]: 'Honduras',
  [Codegen.CountryCode.Jp]: 'Japan',
  [Codegen.CountryCode.Ie]: 'Ireland',
  [Codegen.CountryCode.Ke]: 'Kenya',
  [Codegen.CountryCode.Kg]: 'Kyrgyzstan',
  [Codegen.CountryCode.Mt]: 'Malta',
  [Codegen.CountryCode.Mz]: 'Republic of Mozambique',
  [Codegen.CountryCode.Nc]: 'New Caledonia',
  [Codegen.CountryCode.Aw]: 'Aruba',
  [Codegen.CountryCode.Sk]: 'Slovakia',
  [Codegen.CountryCode.Sz]: 'Swaziland',
  [Codegen.CountryCode.Tz]: 'Tanzania',
  [Codegen.CountryCode.Wf]: 'Wallis and Futuna',
  [Codegen.CountryCode.Sg]: 'Singapore',
  [Codegen.CountryCode.Vn]: 'Vietnam',
  [Codegen.CountryCode.Si]: 'Slovenia',
  [Codegen.CountryCode.So]: 'Somalia',
  [Codegen.CountryCode.Za]: 'South Africa',
  [Codegen.CountryCode.Zw]: 'Zimbabwe',
  [Codegen.CountryCode.Es]: 'Spain',
  [Codegen.CountryCode.Ss]: 'South Sudan',
  [Codegen.CountryCode.Sd]: 'Sudan',
  [Codegen.CountryCode.Eh]: 'Western Sahara',
  [Codegen.CountryCode.Sj]: 'Svalbard and Jan Mayen',
  [Codegen.CountryCode.Sr]: 'Suriname',
  [Codegen.CountryCode.Ch]: 'Switzerland',
  [Codegen.CountryCode.Se]: 'Sweden',
  [Codegen.CountryCode.Sy]: 'Syria',
  [Codegen.CountryCode.Tj]: 'Tajikistan',
  [Codegen.CountryCode.Th]: 'Thailand',
  [Codegen.CountryCode.Tg]: 'Togo',
  [Codegen.CountryCode.Tk]: 'Tokelau',
  [Codegen.CountryCode.To]: 'Tonga',
  [Codegen.CountryCode.Tt]: 'Trinidad and Tobago',
  [Codegen.CountryCode.Ae]: 'United Arab Emirates',
  [Codegen.CountryCode.Tn]: 'Tunisia',
  [Codegen.CountryCode.Tr]: 'Turkey',
  [Codegen.CountryCode.Tm]: 'Turkmenistan',
  [Codegen.CountryCode.Tc]: 'Turks and Caicos Islands',
  [Codegen.CountryCode.Ug]: 'Uganda',
  [Codegen.CountryCode.Tv]: 'Tuvalu',
  [Codegen.CountryCode.Ua]: 'Ukraine',
  [Codegen.CountryCode.Mk]: 'Macedonia',
  [Codegen.CountryCode.Gb]: 'United Kingdom',
  [Codegen.CountryCode.Eg]: 'Egypt',
  [Codegen.CountryCode.Je]: 'Jersey',
  [Codegen.CountryCode.Gg]: 'Guernsey',
  [Codegen.CountryCode.Im]: 'Isle of Man',
  [Codegen.CountryCode.Bf]: 'Burkina Faso',
  [Codegen.CountryCode.Vi]: 'U.S. Virgin Islands',
  [Codegen.CountryCode.Uz]: 'Uzbekistan',
  [Codegen.CountryCode.Uy]: 'Uruguay',
  [Codegen.CountryCode.Ve]: 'Venezuela',
  [Codegen.CountryCode.Ws]: 'Samoa',
  [Codegen.CountryCode.Ye]: 'Yemen',
  [Codegen.CountryCode.Zm]: 'Zambia',
  [Codegen.CountryCode.Ab]: 'Republic of Abkhazia',
  [Codegen.CountryCode.Os]: 'Republic of South Ossetia',
  [Codegen.CountryCode.Af]: 'Afghanistan',
  [Codegen.CountryCode.Al]: 'Albania',
  [Codegen.CountryCode.Aq]: 'Antarctica',
  [Codegen.CountryCode.Dz]: 'Algeria',
  [Codegen.CountryCode.As]: 'American Samoa',
  [Codegen.CountryCode.Ad]: 'Andorra',
  [Codegen.CountryCode.Ag]: 'Antigua and Barbuda',
  [Codegen.CountryCode.Ao]: 'Angola',
  [Codegen.CountryCode.Ar]: 'Argentina',
  [Codegen.CountryCode.Az]: 'Azerbaijan',
  [Codegen.CountryCode.Bs]: 'Bahamas',
  [Codegen.CountryCode.Bh]: 'Bahrain',
  [Codegen.CountryCode.Au]: 'Australia',
  [Codegen.CountryCode.Bd]: 'Bangladesh',
  [Codegen.CountryCode.Bb]: 'Barbados',
  [Codegen.CountryCode.Am]: 'Armenia',
  [Codegen.CountryCode.Be]: 'Belgium',
  [Codegen.CountryCode.Bm]: 'Bermuda',
  [Codegen.CountryCode.Bo]: 'Bolivia',
  [Codegen.CountryCode.Bt]: 'Bhutan',
  [Codegen.CountryCode.Bw]: 'Botswana',
  [Codegen.CountryCode.Ba]: 'Bosnia and Herzegovina',
  [Codegen.CountryCode.Bv]: 'Bouvet Island',
  [Codegen.CountryCode.Br]: 'Brazil',
  [Codegen.CountryCode.Bz]: 'Belize',
  [Codegen.CountryCode.Io]: 'British Indian Ocean Territory',
  [Codegen.CountryCode.Sb]: 'Solomon Islands',
  [Codegen.CountryCode.Vg]: 'British Virgin Islands',
  [Codegen.CountryCode.Bn]: 'Brunei',
  [Codegen.CountryCode.Bg]: 'Bulgaria',
  [Codegen.CountryCode.Mm]: 'Myanmar',
  [Codegen.CountryCode.Bi]: 'Burundi',
  [Codegen.CountryCode.By]: 'Belarus',
  [Codegen.CountryCode.Kh]: 'Cambodia',
  [Codegen.CountryCode.Cm]: 'Cameroon',
  [Codegen.CountryCode.Ca]: 'Canada',
  [Codegen.CountryCode.Cv]: 'Cape Verde',
  [Codegen.CountryCode.Ky]: 'Cayman Islands',
  [Codegen.CountryCode.Cf]: 'Central African Republic',
  [Codegen.CountryCode.Lk]: 'Sri Lanka',
  [Codegen.CountryCode.Cl]: 'Chile',
  [Codegen.CountryCode.Cn]: 'China',
  [Codegen.CountryCode.Tw]: 'Taiwan',
  [Codegen.CountryCode.Cx]: 'Christmas Island',
  [Codegen.CountryCode.Cc]: 'Cocos Islands',
  [Codegen.CountryCode.Co]: 'Colombia',
  [Codegen.CountryCode.Yt]: 'Mayotte',
  [Codegen.CountryCode.Cg]: 'Republic of the Congo',
  [Codegen.CountryCode.Cd]: 'Democratic Republic of the Congo',
  [Codegen.CountryCode.Ck]: 'Cook Islands',
  [Codegen.CountryCode.Cr]: 'Costa Rica',
  [Codegen.CountryCode.Hr]: 'Croatia',
  [Codegen.CountryCode.Cu]: 'Cuba',
  [Codegen.CountryCode.Cy]: 'Cyprus',
  [Codegen.CountryCode.Cz]: 'Czech Republic',
  [Codegen.CountryCode.Bj]: 'Benin',
  [Codegen.CountryCode.Dk]: 'Denmark',
  [Codegen.CountryCode.Dm]: 'Dominica',
  [Codegen.CountryCode.Do]: 'Dominican Republic',
  [Codegen.CountryCode.Ec]: 'Ecuador',
  [Codegen.CountryCode.Sv]: 'El Salvador',
  [Codegen.CountryCode.Gq]: 'Equatorial Guinea',
  [Codegen.CountryCode.Et]: 'Ethiopia',
  [Codegen.CountryCode.Er]: 'Eritrea',
  [Codegen.CountryCode.Ee]: 'Estonia',
  [Codegen.CountryCode.Fo]: 'Faroe Islands',
  [Codegen.CountryCode.Fk]: 'Falkland Islands',
  [Codegen.CountryCode.Gs]: 'South Georgia and the South Sandwich Islands',
  [Codegen.CountryCode.Fj]: 'Fiji',
  [Codegen.CountryCode.Fi]: 'Finland',
  [Codegen.CountryCode.Ax]: 'Aland Islands',
  [Codegen.CountryCode.Fr]: 'France',
  [Codegen.CountryCode.Gf]: 'French Guiana',
  [Codegen.CountryCode.Pf]: 'French Polynesia',
  [Codegen.CountryCode.Tf]: 'French Southern Territories',
  [Codegen.CountryCode.Dj]: 'Djibouti',
  [Codegen.CountryCode.Ge]: 'Georgia',
  [Codegen.CountryCode.Gm]: 'Gambia',
  [Codegen.CountryCode.De]: 'Germany',
  [Codegen.CountryCode.Gh]: 'Ghana',
  [Codegen.CountryCode.Gi]: 'Gibraltar',
  [Codegen.CountryCode.Ki]: 'Kiribati',
  [Codegen.CountryCode.Gr]: 'Greece',
  [Codegen.CountryCode.Gl]: 'Greenland',
  [Codegen.CountryCode.Gd]: 'Grenada',
  [Codegen.CountryCode.Gu]: 'Guam',
  [Codegen.CountryCode.Gt]: 'Guatemala',
  [Codegen.CountryCode.Gn]: 'Guinea',
  [Codegen.CountryCode.Gy]: 'Guyana',
  [Codegen.CountryCode.Ht]: 'Haiti',
  [Codegen.CountryCode.Hm]: 'Heard Island and McDonald Islands',
  [Codegen.CountryCode.Va]: 'Vatican',
  [Codegen.CountryCode.Hk]: 'Hong Kong',
  [Codegen.CountryCode.Hu]: 'Hungary',
  [Codegen.CountryCode.Is]: 'Iceland',
  [Codegen.CountryCode.In]: 'India',
  [Codegen.CountryCode.Id]: 'Indonesia',
  [Codegen.CountryCode.Iq]: 'Iraq',
  [Codegen.CountryCode.Il]: 'Israel',
  [Codegen.CountryCode.It]: 'Italy',
  [Codegen.CountryCode.Ci]: 'Ivory Coast',
  [Codegen.CountryCode.Jm]: 'Jamaica',
  [Codegen.CountryCode.Kz]: 'Kazakhstan',
  [Codegen.CountryCode.Jo]: 'Jordan',
  [Codegen.CountryCode.Kp]: 'North Korea',
  [Codegen.CountryCode.Kr]: 'South Korea',
  [Codegen.CountryCode.Kw]: 'Kuwait',
  [Codegen.CountryCode.La]: 'Laos',
  [Codegen.CountryCode.Lb]: 'Lebanon',
  [Codegen.CountryCode.Ls]: 'Lesotho',
  [Codegen.CountryCode.Lv]: 'Latvia',
  [Codegen.CountryCode.Lr]: 'Liberia',
  [Codegen.CountryCode.Ly]: 'Libya',
  [Codegen.CountryCode.Li]: 'Liechtenstein',
  [Codegen.CountryCode.Lt]: 'Lithuania',
  [Codegen.CountryCode.Lu]: 'Luxembourg',
  [Codegen.CountryCode.Mo]: 'Macao',
  [Codegen.CountryCode.Mg]: 'Madagascar',
  [Codegen.CountryCode.Mw]: 'Malawi',
  [Codegen.CountryCode.My]: 'Malaysia',
  [Codegen.CountryCode.Mv]: 'Maldives',
  [Codegen.CountryCode.Ml]: 'Mali',
  [Codegen.CountryCode.Mq]: 'Martinique',
  [Codegen.CountryCode.Mr]: 'Mauritania',
  [Codegen.CountryCode.Mu]: 'Mauritius',
  [Codegen.CountryCode.Mx]: 'Mexico',
  [Codegen.CountryCode.Mc]: 'Monaco',
  [Codegen.CountryCode.Mn]: 'Mongolia',
  [Codegen.CountryCode.Md]: 'Moldova',
  [Codegen.CountryCode.Me]: 'Montenegro',
  [Codegen.CountryCode.Ms]: 'Montserrat',
  [Codegen.CountryCode.Ma]: 'Morocco',
  [Codegen.CountryCode.Om]: 'Oman',
  [Codegen.CountryCode.Np]: 'Nepal',
  [Codegen.CountryCode.Nl]: 'Netherlands',
  [Codegen.CountryCode.Cw]: 'Curacao',
  [Codegen.CountryCode.Na]: 'Namibia',
  [Codegen.CountryCode.Nr]: 'Nauru',
  [Codegen.CountryCode.Sx]: 'Sint Maarten',
  [Codegen.CountryCode.Bq]: 'Bonaire, Saint Eustatius and Saba ',
  [Codegen.CountryCode.Vu]: 'Vanuatu',
  [Codegen.CountryCode.Nz]: 'New Zealand',
  [Codegen.CountryCode.Ni]: 'Nicaragua',
  [Codegen.CountryCode.Ne]: 'Niger',
  [Codegen.CountryCode.Ng]: 'Nigeria',
  [Codegen.CountryCode.Nu]: 'Niue',
  [Codegen.CountryCode.Nf]: 'Norfolk Island',
  [Codegen.CountryCode.No]: 'Norway',
  [Codegen.CountryCode.Mp]: 'Northern Mariana Islands',
  [Codegen.CountryCode.Um]: 'United States Minor Outlying Islands',
  [Codegen.CountryCode.Fm]: 'Micronesia',
  [Codegen.CountryCode.Mh]: 'Marshall Islands',
  [Codegen.CountryCode.Pw]: 'Palau',
  [Codegen.CountryCode.Pk]: 'Pakistan',
  [Codegen.CountryCode.Pa]: 'Panama',
  [Codegen.CountryCode.Pg]: 'Papua New Guinea',
  [Codegen.CountryCode.Py]: 'Paraguay',
  [Codegen.CountryCode.Pe]: 'Peru',
  [Codegen.CountryCode.Ph]: 'Philippines',
  [Codegen.CountryCode.Pn]: 'Pitcairn',
  [Codegen.CountryCode.Pl]: 'Poland',
  [Codegen.CountryCode.Pt]: 'Portugal',
  [Codegen.CountryCode.Gw]: 'Guinea-Bissau',
  [Codegen.CountryCode.Tl]: 'East Timor',
  [Codegen.CountryCode.Pr]: 'Puerto Rico',
  [Codegen.CountryCode.Qa]: 'Qatar',
  [Codegen.CountryCode.Re]: 'Reunion',
  [Codegen.CountryCode.Ro]: 'Romania',
  [Codegen.CountryCode.Ru]: 'Russia',
  [Codegen.CountryCode.Rw]: 'Rwanda',
  [Codegen.CountryCode.Bl]: 'Saint Barthelemy',
  [Codegen.CountryCode.Sh]: 'Saint Helena',
  [Codegen.CountryCode.Kn]: 'Saint Kitts and Nevis',
  [Codegen.CountryCode.Ai]: 'Anguilla',
  [Codegen.CountryCode.Lc]: 'Saint Lucia',
  [Codegen.CountryCode.Mf]: 'Saint Martin',
  [Codegen.CountryCode.Pm]: 'Saint Pierre and Miquelon',
  [Codegen.CountryCode.Vc]: 'Saint Vincent and the Grenadines',
  [Codegen.CountryCode.Sm]: 'San Marino',
  [Codegen.CountryCode.St]: 'Sao Tome and Principe',
  [Codegen.CountryCode.Sa]: 'Saudi Arabia',
  [Codegen.CountryCode.Sn]: 'Senegal',
  [Codegen.CountryCode.Rs]: 'Serbia',
  [Codegen.CountryCode.Sc]: 'Seychelles',
  [Codegen.CountryCode.Sl]: 'Sierra Leone',
};

export enum CountryCode {
  US = 'US',
  AT = 'AT',
  KM = 'KM',
  GA = 'GA',
  PS = 'PS',
  GP = 'GP',
  IR = 'IR',
  HN = 'HN',
  JP = 'JP',
  IE = 'IE',
  KE = 'KE',
  KG = 'KG',
  MT = 'MT',
  MZ = 'MZ',
  NC = 'NC',
  AW = 'AW',
  SK = 'SK',
  SZ = 'SZ',
  TZ = 'TZ',
  WF = 'WF',
  SG = 'SG',
  VN = 'VN',
  SI = 'SI',
  SO = 'SO',
  ZA = 'ZA',
  ZW = 'ZW',
  ES = 'ES',
  SS = 'SS',
  SD = 'SD',
  EH = 'EH',
  SJ = 'SJ',
  SR = 'SR',
  CH = 'CH',
  SE = 'SE',
  SY = 'SY',
  TJ = 'TJ',
  TH = 'TH',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  AE = 'AE',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  // eslint-disable-next-line max-lines
  UG = 'UG',
  TV = 'TV',
  UA = 'UA',
  MK = 'MK',
  GB = 'GB',
  EG = 'EG',
  JE = 'JE',
  GG = 'GG',
  IM = 'IM',
  BF = 'BF',
  VI = 'VI',
  UZ = 'UZ',
  UY = 'UY',
  VE = 'VE',
  WS = 'WS',
  YE = 'YE',
  ZM = 'ZM',
  AB = 'AB',
  OS = 'OS',
  AF = 'AF',
  AL = 'AL',
  AQ = 'AQ',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AG = 'AG',
  AO = 'AO',
  AR = 'AR',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  AU = 'AU',
  BD = 'BD',
  BB = 'BB',
  AM = 'AM',
  BE = 'BE',
  BM = 'BM',
  BO = 'BO',
  BT = 'BT',
  BW = 'BW',
  BA = 'BA',
  BV = 'BV',
  BR = 'BR',
  BZ = 'BZ',
  IO = 'IO',
  SB = 'SB',
  VG = 'VG',
  BN = 'BN',
  BG = 'BG',
  MM = 'MM',
  BI = 'BI',
  BY = 'BY',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  LK = 'LK',
  CL = 'CL',
  CN = 'CN',
  TW = 'TW',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  YT = 'YT',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  CY = 'CY',
  CZ = 'CZ',
  BJ = 'BJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  SV = 'SV',
  GQ = 'GQ',
  ET = 'ET',
  ER = 'ER',
  EE = 'EE',
  FO = 'FO',
  FK = 'FK',
  GS = 'GS',
  FJ = 'FJ',
  FI = 'FI',
  AX = 'AX',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  DJ = 'DJ',
  GE = 'GE',
  GM = 'GM',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  KI = 'KI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GU = 'GU',
  GT = 'GT',
  GN = 'GN',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IQ = 'IQ',
  IL = 'IL',
  IT = 'IT',
  CI = 'CI',
  JM = 'JM',
  KZ = 'KZ',
  JO = 'JO',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  LA = 'LA',
  LB = 'LB',
  LS = 'LS',
  LV = 'LV',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  MX = 'MX',
  MC = 'MC',
  MN = 'MN',
  MD = 'MD',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  OM = 'OM',
  NP = 'NP',
  NL = 'NL',
  CW = 'CW',
  NA = 'NA',
  NR = 'NR',
  SX = 'SX',
  BQ = 'BQ',
  VU = 'VU',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  NO = 'NO',
  MP = 'MP',
  UM = 'UM',
  FM = 'FM',
  MH = 'MH',
  PW = 'PW',
  PK = 'PK',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  GW = 'GW',
  TL = 'TL',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  AI = 'AI',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
}
