import { Attributes, merge } from '@ml/shared/permissions';

import { License, Provider, User } from '@/graphql';

const {
  Common,
  License: L, LicenseType: LT,
  Provider: P, ProviderTenancy: PT,
  Document: D,
} = Attributes;

export interface IEvalOptions {
  user: User;
  license?: License;
  provider?: Provider;
}

type Tags = (string | string[])[];
export type TagResolvers<T> = Record<string, (opts: T & { args: string[] }) => boolean | undefined>;

const commonResolvers: TagResolvers<unknown> = {
  [Common.All]: () => true,
};

const providerResolvers: TagResolvers<IEvalOptions> = {
  [merge(P.Tenancy, PT.Assigned)]: ({ user: u, provider: p }) => p?.assignees?.some(a => a.id === u.id),
  [merge(P.Tenancy, PT.Organization)]: ({ user: u, provider: p }) => p?.organizations?.some(o => o.id === u.org_id),
  [merge(P.Tenancy, PT.B2B)]: ({ user: u, provider: p }) => p?.organizations?.every(o => o.id !== u.org_id),
  [merge(P.Tenancy, PT.B2C)]: ({ provider: p }) => p?.organizations?.length === 0,
};

const licenseResolvers: TagResolvers<IEvalOptions> = {
  [L.Type]: ({ license, args }) => license?.type.toLowerCase() === args[0],
};

const docResolvers: TagResolvers<IEvalOptions> = {
  [D.Privacy]: () => true,
};

const allResolvers: TagResolvers<IEvalOptions> = {
  ...docResolvers,
  ...commonResolvers,
  ...licenseResolvers,
  ...providerResolvers,
};

const evaluate = (tags: Tags, opts: IEvalOptions) => {
  for (const tag of tags) {
    const arr = Array.isArray(tag) ? tag : [tag];

    // eslint-disable-next-line unicorn/no-array-reduce
    const groupResult = arr.reduce((acc, t) => {
      const split = t.split(':');

      const tag = split[0];
      const args = split.length > 1 ? split[1].split(',') : [];

      const resolverKey = Object.keys(allResolvers).find(r => [tag, t].includes(r));

      if (!resolverKey) {
        // eslint-disable-next-line no-console
        console.warn(`Tag [${t}] is not implemented`);

        return false;
      }

      return Boolean(acc && allResolvers[resolverKey]({
        ...opts,
        args,
      }));
    }, true as boolean);

    if (groupResult) {
      return true;
    }
  }

  return false;
};

export const useTagEval = () => ({
  evaluate,
});
