import BadgeDirective from 'primevue/badgedirective';
import PColumn from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import type { App } from 'vue';
import { defineAsyncComponent } from 'vue';

// eslint-disable-next-line import/no-default-export
export default {
  install(Vue: App) {
    // Services
    Vue.use(ToastService);
    Vue.use(ConfirmationService);

    // Directives
    Vue.directive('badge', BadgeDirective);
    Vue.directive('tooltip', Tooltip);
    Vue.directive('ripple', Ripple);
    Vue.directive('styleclass', StyleClass);

    // Components
    Vue.component('PColumn', PColumn);

    Vue.component('PAccordion', defineAsyncComponent(() => import('primevue/accordion')));
    Vue.component('PAccordionTab', defineAsyncComponent(() => import('primevue/accordiontab')));
    Vue.component('PAvatar', defineAsyncComponent(() => import('primevue/avatar')));
    Vue.component('PAvatarGroup', defineAsyncComponent(() => import('primevue/avatargroup')));
    Vue.component('PButton', defineAsyncComponent(() => import('primevue/button')));
    Vue.component('PPicker', defineAsyncComponent(() => import('primevue/datepicker')));
    Vue.component('PConfirmDialog', defineAsyncComponent(() => import('primevue/confirmdialog')));
    Vue.component('PCard', defineAsyncComponent(() => import('primevue/card')));
    Vue.component('PCarousel', defineAsyncComponent(() => import('primevue/carousel')));
    Vue.component('PCheckbox', defineAsyncComponent(() => import('primevue/checkbox')));
    Vue.component('PChip', defineAsyncComponent(() => import('primevue/chip')));
    Vue.component('PDialog', defineAsyncComponent(() => import('primevue/dialog')));
    Vue.component('PSlider', defineAsyncComponent(() => import('primevue/slider')));
    Vue.component('PMultiSelect', defineAsyncComponent(() => import('primevue/multiselect')));
    Vue.component('PDataTable', defineAsyncComponent(() => import('primevue/datatable')));
    Vue.component('PDivider', defineAsyncComponent(() => import('primevue/divider')));
    Vue.component('PSelect', defineAsyncComponent(() => import('primevue/select')));
    Vue.component('PFileUpload', defineAsyncComponent(() => import('primevue/fileupload')));
    Vue.component('PInputNumber', defineAsyncComponent(() => import('primevue/inputnumber')));
    Vue.component('PToggleSwitch', defineAsyncComponent(() => import('primevue/toggleswitch')));
    Vue.component('PInputText', defineAsyncComponent(() => import('primevue/inputtext')));
    Vue.component('PKnob', defineAsyncComponent(() => import('primevue/knob')));
    Vue.component('PMenu', defineAsyncComponent(() => import('primevue/menu')));
    Vue.component('PProgressBar', defineAsyncComponent(() => import('primevue/progressbar')));
    Vue.component('PProgressSpinner', defineAsyncComponent(() => import('primevue/progressspinner')));
    Vue.component('PRadioButton', defineAsyncComponent(() => import('primevue/radiobutton')));
    Vue.component('PRating', defineAsyncComponent(() => import('primevue/rating')));
    Vue.component('PDrawer', defineAsyncComponent(() => import('primevue/drawer')));
    Vue.component('PTabMenu', defineAsyncComponent(() => import('primevue/tabmenu')));
    Vue.component('PTabPanel', defineAsyncComponent(() => import('primevue/tabpanel')));
    Vue.component('PTabView', defineAsyncComponent(() => import('primevue/tabview')));
    Vue.component('PTag', defineAsyncComponent(() => import('primevue/tag')));
    Vue.component('PTextarea', defineAsyncComponent(() => import('primevue/textarea')));
    Vue.component('PToast', defineAsyncComponent(() => import('primevue/toast')));
    Vue.component('PPaginator', defineAsyncComponent(() => import('primevue/paginator')));
    Vue.component('POverlayPanel', defineAsyncComponent(() => import('primevue/overlaypanel')));
    Vue.component('PContextMenu', defineAsyncComponent(() => import('primevue/contextmenu')));
    Vue.component('PInputMask', defineAsyncComponent(() => import('primevue/inputmask')));
    Vue.component('PInputGroup', defineAsyncComponent(() => import('primevue/inputgroup')));
    Vue.component('PPortal', defineAsyncComponent(() => import('primevue/portal')));
  },
};
