<template>
  <component
    :is="layout"
    :key="layout.name"
  />
<!--  <app-feedback />-->
</template>

<script setup lang="ts">
import { DefaultApolloClient, provideApolloClient } from '@vue/apollo-composable';
import { provide } from 'vue';

// import { AppFeedback } from '@/modules/feedback';
import { useShutdownSubscription } from './graphql';
import { apolloClient } from './util/graphql';

provide(DefaultApolloClient, apolloClient);
provideApolloClient(apolloClient);

const { onResult } = useShutdownSubscription();

onResult(res => {
  if (res.data?.shutdown) {
    wsClient.terminate();
  }
});

const { component: layout } = useLayout();
</script>
