import type { Client, ClientOptions, Event } from 'graphql-ws';

type WSEventsMap = ClientOptions['on'];

let activeSocket: WebSocket | null = null;
let timedOut: number | undefined;

export const mapWsEvents = (wsClient: Client) => {
  const globals = useGlobalsStore();

  const wsEvents: WSEventsMap = {
    connected: socket => {
      globals.setConnectionStatus(true);

      activeSocket = socket as WebSocket;
    },
    closed: () => {
      globals.setConnectionStatus(false);

      activeSocket = null;
    },
    ping: () => {
      timedOut = window.setTimeout(() => {
        if (!activeSocket) {
          return;
        }

        // do not remove, useful for sentry
        // eslint-disable-next-line no-console
        console.error('WS timeout');

        globals.setConnectionStatus(false);

        activeSocket.close(4408, 'Request Timeout');
      }, 5000);
    },
    pong: received => {
      if (received) {
        window.clearTimeout(timedOut);
      }
    },
  };

  Object.entries(wsEvents)
    .map(([event, callback]) => wsClient.on(event as Event, callback));
};
