export const mapEdges = <T>(response?: { edges: { node: T }[] }) => {
  if (!response) {
    return [];
  }

  return response.edges.map((e: { node: T }) => e.node);
};

export const mapNodes = <T>(response: { nodes: T[] }) => {
  if (!response) {
    return [];
  }

  return response.nodes.map((e: T) => e);
};
