import { computed } from 'vue';
import { useRoute } from 'vue-router';

const LayoutMap = {
  auth: defineAsyncComponent(() => import('../components/auth-layout.vue')),
  admin: defineAsyncComponent(() => import('../components/admin/admin-layout.vue')),
  provider: defineAsyncComponent(() => import('../components/provider/provider-layout.vue')),
  sharePdf: defineAsyncComponent(() => import('../components/share-pdf-layout.vue')),
};

export const useLayout = () => {
  const route = useRoute();
  const auth = useAuthStore();

  const component = computed(() => {
    if (route.meta?.layout) {
      return LayoutMap[route.meta.layout as 'auth' | 'admin' | 'provider' | 'sharePdf'];
    }

    if (!auth.isAuthorized) {
      return LayoutMap.auth;
    }

    if (auth.isProvider) {
      return LayoutMap.provider;
    }

    return LayoutMap.admin;
  });

  return {
    component,
  };
};
