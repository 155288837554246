import { Resource } from './index';
import { AttributesList } from './interface';
import { Action } from './resources';

export const ares = (action: Action, resource: string) => `${action}.${resource}`;

export const merge = (
  attribute: any /* Attribute */,
  ...params: string[]
): string => `${attribute}:${params.join(',')}`;

export const getAllowedFields = (attributes?: AttributesList) => {
  if (!attributes) {
    return [];
  }

  return attributes
    .flat()
    .filter(s => typeof s === 'string' && s.startsWith('field:'))
    .map(s => s.replace('field:', ''));
};

export const getColumn = (ctx: { resource: Resource }) => (ctx.resource === Resource.Provider ? 'id' : 'provider_id');

export const asPids = (ctx: { resource: Resource }, pIds: number[]) => {
  const col = getColumn(ctx);

  return {
    [col]: {
      in: pIds,
    },
  };
};

export class Attribute {
  public key: string;

  public args: string[];

  constructor(
    public source: string,
  ) {
    const [key, args] = source.split(':');

    this.key = key;

    if (args) {
      this.args = args.split(',');
    }
  }

  is(cmp: string) {
    return [this.key, this.source].includes(cmp);
  }

  arg(pos: number) {
    return this.args.length > pos ? this.args[pos] : null;
  }
}
