export const getEnv = (key: keyof IRuntimeConfig) => {
  if (import.meta.env.TEST) {
    return (import.meta.env as IRuntimeConfig)[key];
  }

  if (window && window.runtimeConfig && key in window.runtimeConfig) {
    return window.runtimeConfig[key];
  }

  return '';
};
