import { ApolloQueryResult } from '@apollo/client/core';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';

import { CmeSettings, GetCmeSettingsQuery, useGetCmeSettingsLazyQuery } from '@/graphql';

export const useCmeSettingsStore = defineStore({
  id: 'cme-settings',

  state: () => ({
    settings: {
      renew: [],
      initial: [],
      general: [],
    },
  }),
  actions: {
    fetch() {
      const {
        load, result: queryResult, onResult,
      } = useGetCmeSettingsLazyQuery();

      const result = ref();

      watch(queryResult, res => {
        result.value = res?.getCmeSettings;
        if (result.value) this.setSettings(result.value as Record<string, []>);
      }, {
        immediate: true,
      });

      void load();

      onResult((response: ApolloQueryResult<GetCmeSettingsQuery>) => {
        if (!response.data?.getCmeSettings) return;

        result.value = response.data.getCmeSettings;

        this.setSettings(result.value as Record<string, []>);
      });
    },
    get(direction: never): CmeSettings[] {
      return this.settings[direction];
    },
    set(settings: Record<string, unknown>[], direction: never) {
      Object.assign(this.settings[direction], settings);
    },
    setSettings(settings: Record<string, []>) {
      for (const key of Object.keys(settings)) {
        if (key === '__typename') continue;

        const direction = key;

        if (settings[direction]) this.set(cloneDeep(settings[direction]), direction as never);
      }
    },
  },
});
