import { defineStore } from 'pinia';

export interface MenuState {
  sub_visible: boolean
  isOpen: boolean
  total: {
    'licenses-list': number
  }
}

const getFromLS = () => {
  const val = window.localStorage.getItem('submenu_visible');

  if (val === null) {
    return true;
  }

  return val !== 'false';
};

export const useMenuStore = defineStore({
  id: 'menu',
  state: () => ({
    sub_visible: getFromLS(),
    isOpen: false,
    total: {
      'licenses-list': 0,
    },
  } satisfies MenuState),

  actions: {
    toggle() {
      this.sub_visible = !this.sub_visible;

      window.localStorage.setItem('submenu_visible', this.sub_visible.toString());
    },

    openMenu() {
      // @ts-ignore: ??
      this.isOpen = true;
    },
    closeMenu() {
      this.isOpen = false;
    },

    set(data: Record<string, unknown>) {
      Object.assign(this, data);
    },
  },
  getters: {
    getTotal: state => state.total,
  },
});
