import { SortDirection } from '@ml/shared';
import { cloneDeep } from 'lodash-es';
import { watch } from 'vue';

import { TableSettings, useCreateTableSettingsMutation, useTableSettingsQuery } from '@/graphql';
import { columnsByRoute } from '@/modules/filters/constants/filter-settings-defaults';
import { ColumnsByRoutes, FilterSettingsStateType } from '@/modules/filters/models/filter-settings';
import { useMoreColumns } from '@/modules/filters/use/more-columns';

export const useFilterSettings = () => {
  const route = useRoute();

  const { moreColumnsByRoute } = useMoreColumns();

  const { mutate: createTableSettings } = useCreateTableSettingsMutation();

  const data = ref<Record<string, FilterSettingsStateType>>({});
  const isSet = ref(false);
  const settingsId = ref();
  const isInit = ref(false);
  const showTable = ref(true);

  const defaultPrimeDataTable = '';

  const defaultViewOptions: FilterSettingsStateType = {
    defaultView: true,
    smartFilters: true,
    avatars: true,
    multiSelect: true,
    columns: [],
    moreColumns: [],
    sortBy: 'updatedAt',
    sortDirection: SortDirection.DESC,
    primeDataTable: defaultPrimeDataTable,
  };

  const defaultColumns = {
    ...columnsByRoute,
  };

  const defaultMoreColumns = {
    ...moreColumnsByRoute,
  };

  const set = (value: Record<string, FilterSettingsStateType>) => {
    isSet.value = true;

    if (route?.name && !value[route?.name as string]) {
      value[route.name as string] = {
        ...defaultViewOptions,
        columns: defaultColumns[route.name as keyof ColumnsByRoutes],
        moreColumns: defaultMoreColumns[route.name as keyof ColumnsByRoutes],
      };
    }

    data.value = value;
  };

  const setOne = (value: FilterSettingsStateType) => {
    data.value[route.name as string] = value;
  };

  const fetch = (user_id: number) => {
    const { result } = useTableSettingsQuery({
      filter: {
        user_id: {
          eq: user_id,
        },
      },
    });

    watch(result, async () => {
      if (isInit.value) return;

      if (result.value?.tableSettings) {
        const list = mapEdges(result.value?.tableSettings);
        if (list.length === 0) {
          const response = await createTableSettings({
            data: {
              user_id,
              settings: data.value,
            },
          });

          if (response?.data?.createOneTableSettings) {
            settingsId.value = response.data.createOneTableSettings.id;
          }

          isInit.value = true;
        }

        if (list.length > 0) {
          const data = list[0] as TableSettings;
          settingsId.value = data.id;
          const settings = cloneDeep(data.settings as Record<string, FilterSettingsStateType>);
          const dataByToute = settings[route.name as string];

          set(settings);

          if (dataByToute?.primeDataTable) {
            showTable.value = false;
          }

          isInit.value = true;
        }
      }
    }, {
      immediate: true,
    });
  };

  return {
    settingsId,
    isSet,
    showTable,
    defaultViewOptions,
    defaultColumns,
    defaultMoreColumns,
    defaultPrimeDataTable,
    data,
    fetch,
    set,
    setOne,
  };
};
