<template>
  <svg
    v-if="!name.includes(':') && !name.includes('wrapper:')"
    class="icon"
    :style="{ width: size, height: size, margin: margin, stroke: normalizedColor, strokeWidth: width }"
  >
    <use :xlink:href="'#icon-' + props.name" />
  </svg>
  <wrapper
    v-else-if="name.includes('wrapper:')"
    :src="import(`../../../../assets/icons/${name.replace('wrapper:', '')}.svg`)"
    :style="{margin: margin, stroke: normalizedColor, strokeWidth: width }"
    :width="size"
    :height="size"
  />

  <Icon
    v-else
    :icon="props.name"
    :width="size"
    :height="size"
    :color="color"
  />
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue';

import Wrapper from './wrapper.vue';

const props = withDefaults(defineProps<{
  /**
   * Link to sprite symbol
   */
  name: string;
  /**
   * Icon size (default unit is px)
   */
  size?: string | number;
  /**
   * SVG container margin
   */
  margin?: string;

  /**
   * SVG stroke color
   */
  color?: string | false;

  /**
   * SVG stroke width
   */
  width?: 'normal' | 'thin' | 'bold';
}>(), {
  size: 24,
  margin: '',
  width: 'normal',
  color: undefined,
});

const normalizedColor = computed(() => {
  if (props.color === false) {
    return;
  }

  return normalizeColor(props.color);
});

const isNumber = (val: unknown): val is number => typeof val === 'number' || /^\d+$/.test(String(val));

const size = computed(() => {
  const defaultUnix = 'px';

  if (isNumber(props.size)) {
    return props.size + defaultUnix;
  }

  return props.size;
});

const margin = computed(() => {
  const defaultUnix = 'px';

  if (isNumber(props.margin)) {
    return props.margin + defaultUnix;
  }

  return props.margin;
});

const width = computed(() => ({
  normal: '1.67px',
  thin: '1.33px',
  bold: '2px',
}[props.width as string]));

const iconifyWidth = computed(() => ({
  normal: '2.67px',
  thin: '2px',
  bold: '4px',
}[props.width as string]));
</script>

<style lang="scss">
.icon {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.iconify > g {
  stroke-width: v-bind(iconifyWidth);
}
</style>
