import { AddressPurpose } from '@/graphql';
import { FilterMode, FilterType } from '@/modules/filters';

export const useMoreColumns = () => {
  const { options: states } = useStates();

  const getAddressStateFilterGraphql = (purpose: AddressPurpose, field: 'home_address_states' | 'mailing_address_states') => (
    {
      [field]: {
        purpose: {
          eq: purpose,
        },
      },
    }
  );

  const idColumn = {
    label: 'ID',
    field: 'id',
    value: false,
    isMore: true,
  };

  const moreColumnsByRoute = {
    licenses: [
      idColumn,
      {
        label: 'Home Address (State)',
        field: 'home_address_states',
        value: false,
        isMore: true,
        notSort: true,
        format: 'addressState',
        filter: {
          field: 'home_address_states.state_code',
          type: FilterType.Selection,
          options: states,
          mode: FilterMode.IN,
          graphqlFilter: getAddressStateFilterGraphql(AddressPurpose.Home, 'home_address_states'),
        },
      },
      {
        label: 'Mailing Address (State)',
        field: 'mailing_address_states',
        value: false,
        isMore: true,
        notSort: true,
        format: 'addressState',
        filter: {
          field: 'mailing_address_states.state_code',
          type: FilterType.Selection,
          options: states,
          mode: FilterMode.IN,
          graphqlFilter: getAddressStateFilterGraphql(AddressPurpose.Mailing, 'mailing_address_states'),
        },
      },
      {
        label: 'Specialty',
        field: 'provider.specialty',
        value: false,
        isMore: true,
        notSort: true,
        filter: true,
      },
    ],
    'licenses-auto-renewal': [
      idColumn,
    ],
    'licenses-all-requests': [
      idColumn,
    ],
    'licenses-completed': [],
    'licenses-closed': [],
    providers: [
      idColumn,
    ],
    'my-providers': [
      idColumn,
    ],
    documents: [
      idColumn,
    ],
    logins: [
      idColumn,
    ],
    organizations: [
      idColumn,
    ],
    'settings-team': [
      idColumn,
    ],
    'settings-providers': [
      idColumn,
    ],
    'provider-groups': [
      idColumn,
    ],
    'group-profile': [
      idColumn,
    ],
  };

  return {
    moreColumnsByRoute,
  };
};
