import { EnumMapping } from './types';

export enum UserStatus {
  NotInvited = 'not_invited',
  Invited = 'invited',
  Hold = 'hold',
  Blocked = 'blocked',
  Active = 'active',
  Expired = 'expired',
  Trial = 'trial',
}

export const UserStatusMapping: EnumMapping<UserStatus> = {
  [UserStatus.NotInvited]: 'Not Invited',
  [UserStatus.Invited]: 'Invited',
  [UserStatus.Hold]: 'Hold',
  [UserStatus.Blocked]: 'Blocked',
  [UserStatus.Active]: 'Active',
  [UserStatus.Expired]: 'Expired',
  [UserStatus.Trial]: 'Trial',
};
