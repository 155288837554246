export interface Option<T> { value: T, label: string }

interface EnumUseOptions { nullable?: boolean, sortBySource?: Record<string, string> }

interface EnumUse<T extends string> {
  find: (value: T | string) => string;
  options: (Option<T> | Option<null>)[];
}

export const getEnumKeyByValue = (item: Record<string, unknown>, value: string) => Object.keys(item)[Object.values(item).indexOf(value)];

export const createEnumUse = <T extends string>(
  source: Record<string, T>,
  mapping: Partial<Record<T, string>> = {},
): (options?: EnumUseOptions) => EnumUse<T> => {
  const merged: Record<T, string> = {
    ...Object.fromEntries(Object.values(source).map(value => [value, value])),
    ...mapping,
  };

  // eslint-disable-next-line
  const requiredOptions = Object.entries<string>(merged).map(([value, label]) => ({
    value, label,
  } as Option<T>));
  const nullableOptions = [{
    value: null, label: '--',
  }, ...requiredOptions];

  const find = (value: T | string) => merged[value as T] || '';

  const sortOptions = (sortSource: Record<string, string>) => {
    const sortMerged: Record<T, string> = {
      ...Object.fromEntries(Object.keys(sortSource).map(value => [value, value])),
      ...mapping,
    };

    // eslint-disable-next-line
    return Object.entries<string>(sortMerged).map(([value, label]) => ({
      value, label,
    } as Option<T>));
  };

  return ({ nullable, sortBySource } = {}) => {
    let options: (Option<null> | Option<T>)[];
    if (nullable) options = nullableOptions;
    if (!nullable) options = requiredOptions;
    if (sortBySource && !nullable) options = sortOptions(sortBySource);
    if (sortBySource && nullable) {
      options = [{
        value: null, label: '--',
      }, ...sortOptions(sortBySource)];
    }

    return ({
      find,
      // @ts-ignore: ??
      options,
    });
  };
};
