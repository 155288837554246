import { nextTick, watch, WritableComputedRef } from 'vue';

import { useUpdateTableSettingsMutation } from '@/graphql';
import { ColumnsByRoutes, FilterSettingsStateType, FilterSettingsStore } from '@/modules/filters/models/filter-settings';

export const useFilterSettingsStore = defineStore('filter-settings', () => {
  const authStore = useAuthStore();

  const {
    fetch,
    set,
    setOne,
    data,
    settingsId,
    defaultViewOptions,
    defaultColumns,
    defaultMoreColumns,
    defaultPrimeDataTable,
    isSet,
    showTable,
  } = useFilterSettings();

  const { mutate: updateTableSettings } = useUpdateTableSettingsMutation();

  const route = useRoute();
  const isSetDefaults = ref(false);
  const fullScreenToogle = ref();
  const isChangeRoute = ref(false);

  const setDefaultsPrimeDataTable = () => {
    localStorage[`table-data-${route?.name as string}`] = defaultPrimeDataTable;
    showTable.value = false;
    viewOptions.primeDataTable.value = defaultPrimeDataTable;
  };

  const setToDefaults = (resetTableData = false) => {
    isSetDefaults.value = true;

    data.value[route.name as string] = {
      ...defaultViewOptions,
      columns: defaultColumns[route.name as keyof ColumnsByRoutes],
      moreColumns: defaultMoreColumns[route.name as keyof ColumnsByRoutes],
    };

    if (resetTableData) {
      setDefaultsPrimeDataTable();
    }
  };

  if (authStore.user?.id) {
    fetch(authStore.user.id);
  }

  watch(route, () => {
    isChangeRoute.value = true;

    if (!data.value[route.name as string]) {
      setToDefaults();
    }

    showTable.value = false;
    setTimeout(() => {
      isChangeRoute.value = false;
    }, 100);
  });

  watch(showTable, () => {
    if (!showTable.value && data.value[route?.name as string]?.primeDataTable) {
      localStorage[`table-data-${route?.name as string}`] = data.value[route?.name as string].primeDataTable;
    }

    if (!showTable.value) {
      void nextTick(() => {
        showTable.value = true;
      });
    }
  });

  // eslint-disable-next-line
  const viewOptions: Record<string, WritableComputedRef<any>> = {};
  for (const key of Object.keys(defaultViewOptions)) {
    viewOptions[key] = computed({
      get() {
        return data.value[route?.name as string]?.[key as keyof FilterSettingsStateType];
      },
      set(value) {
        // @ts-ignore: ??
        if (data.value[route?.name as string]?.[key as keyof FilterSettingsStateType]) data.value[route?.name as string][key as keyof FilterSettingsStateType] = value;
      },
    });
  }

  const toWatch = ['multiSelect'];
  for (const key of toWatch) {
    watch(viewOptions[key], () => {
      if (isSet.value || isSetDefaults.value || isChangeRoute.value) {
        return;
      }

      setDefaultsPrimeDataTable();
    });
  }

  watch(data, async () => {
    if (isSet.value) {
      isSet.value = false;
      return false;
    }

    if (settingsId.value && !authStore.isProvider) {
      await updateTableSettings({
        id: settingsId.value as number,
        data: {
          settings: data.value,
        },
      });
    }

    if (isSetDefaults.value) {
      isSetDefaults.value = false;
      return false;
    }

    if (data.value[route.name as string].defaultView
      && JSON.stringify(data.value[route.name as string]) !== JSON.stringify({
        ...defaultViewOptions,
      })) {
      data.value[route.name as string].defaultView = false;
    }
  }, {
    deep: true,
  });

  // eslint-disable-next-line
  return {
    set,
    setOne,
    setToDefaults,
    setDefaultsPrimeDataTable,
    fetch,
    fullScreenToogle,
    data,
    ...viewOptions,
    showTable,
  } as FilterSettingsStore;
});
