import { getCurrentInstance } from 'vue';

export const useGlobal = () => {
  const app = getCurrentInstance();
  if (!app) throw new Error('App is not defined!');

  const { $events } = app.appContext.config.globalProperties;

  const onEvents = (events: { name: string; callback: (data: unknown) => void }[]): void => {
    for (const event of events) {
      $events.$on(event.name, event.callback);
    }
  };

  const offEvents = (events: string[]): void => {
    for (const event of events) {
      $events.$off(event);
    }
  };

  return {
    $events, onEvents, offEvents,
  };
};
