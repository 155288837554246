import { JobDirectionType } from './job-direction-type';

export const delayOptions: { [K in JobDirectionType]: { time: number } } = {
  [JobDirectionType.Manual]: {
    time: 5 * 1000,
  },
  [JobDirectionType.Auto]: {
    time: 60 * 5 * 1000,
  },
};
