import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

import { bluegray, bluelight, gray, green, indigo, pink, primary, purple, red, rose, yellow } from '@/preset/colors';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const CredsyPreset = definePreset(Aura, {
  semantic: {
    primary,
    blue: primary,
    gray,
    red,
    error: red,
    yellow,
    warning: yellow,
    green,
    success: green,
    bluegray,
    'blue-light': bluelight,
    cyan: bluelight,
    indigo,
    purple,
    pink,
    rose,
    // colorScheme: {
    //   light: {
    //     primary: {
    //       color: '{primary.950}',
    //       contrastColor: '#ffffff',
    //       hoverColor: '{primary.800}',
    //       activeColor: '{primary.700}'
    //     },
    //     highlight: {
    //       background: '{primary.950}',
    //       focusBackground: '{primary.700}',
    //       color: '#ffffff',
    //       focusColor: '#ffffff'
    //     }
    //   },
    //   dark: {
    //     primary: {
    //       color: '{primary.50}',
    //       contrastColor: '{primary.950}',
    //       hoverColor: '{primary.200}',
    //       activeColor: '{primary.300}'
    //     },
    //     highlight: {
    //       background: '{primary.50}',
    //       focusBackground: '{primary.300}',
    //       color: '{primary.950}',
    //       focusColor: '{primary.950}'
    //     }
    //   }
    // }
  },
});
