export enum User {
  Self = 'user:self',
  Team = 'user:team',
  Provider = 'user:provider',
}

export enum UserInvite {
  Mass = 'invite:mass',
  Single = 'invite:single',
}

export enum UserField {
  Role = 'role',
  Status = 'status',

  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  Phone = 'phone',
  Password = 'password',
}
