import { InMemoryCacheConfig } from '@apollo/client/cache';

import { apolloClient } from '@/util/graphql';

export const CACHE_OPTIONS = {
  typePolicies: {
    Npi: {
      keyFields: ['number'],
    },
    Provider: {
      fields: {
        assignees: {
          // eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
          merge(existing = [], incoming: unknown[]) {
            return [...incoming];
          },
        },
      },
    },
    LicenseRequest: {
      fields: {
        statuses: {
          // eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
          merge(existing = [], incoming: unknown[]) {
            return [...incoming];
          },
        },
      },
    },
    Address: {
      fields: {
        state: {
          // eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
          merge(existing = {}, incoming: unknown[]) {
            return {
              ...incoming,
            };
          },
        },
      },
    },
  },
} satisfies InMemoryCacheConfig;

export const updateCacheField = (info: { id: number | string; type: string; fieldName: string }, callback: () => void) => {
  apolloClient.cache.modify({
    id: apolloClient.cache.identify({
      id: info.id, __typename: info.type,
    }),
    fields: {
      [info.fieldName]: callback,
    },
  });
};
